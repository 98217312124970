import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase"; // Adjust this path to where your Firebase configuration and initialization are located

/**
 * Fetches guide JSON data from public storage given a uid and recordingSessionId.
 *
 * @param {string} uid User ID associated with the guide.
 * @param {string} recordingSessionId The unique ID of the recording session (guide).
 * @returns {Promise<Object|null>} The guide data as a JSON object, or null if an error occurs.
 */
const getJSONfromPublic = async (
  uid = "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  recordingSessionId
) => {
  const guidePublicPath = `/public/${uid}/${recordingSessionId}/${recordingSessionId}.json`;

  try {
    const url = await getDownloadURL(ref(storage, guidePublicPath));
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch guide data: ${response.statusText}`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching guide data:", error);
    return null; // Optionally, you could throw an error here instead of returning null
  }
};

export default getJSONfromPublic;
