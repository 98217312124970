import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCopy } from "react-icons/fi";

const GuideHTML = ({ guideData }) => {
  const [htmlString, setHtmlString] = useState("");
  const [markdownString, setMarkdownString] = useState("");
  const [showLandscapePreview, setShowLandscapePreview] = useState(false);
  const [showPortraitPreview, setShowPortraitPreview] = useState(false);

  const { settings, media } = guideData;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!"); // You can replace this with a more sophisticated feedback mechanism
    });
  };

  useEffect(() => {
    // Generate HTML string
    let newHtmlString = `<div>\n<h1>${guideData.guideTitle}</h1>\n`;
    guideData.steps.forEach((step, index) => {
      newHtmlString += `<div>\n<h2>Step ${index + 1}: ${step.stepTitle}</h2>\n${
        step.stepDescription
      }\n<img src="${step.screenshotUrl}" alt="Step ${index + 1}">\n</div>\n`;
    });
    newHtmlString += "</div>";
    setHtmlString(newHtmlString);
  }, [guideData]);

  //   / Function to determine base URL based on the current location
  const getBaseURL = () => {
    return window.location.origin.includes("localhost")
      ? "http://localhost:3000"
      : "https://www.kimavi.com";
  };

  return (
    <div className="container mx-auto p-4">
      <Tabs>
        <TabList>
          <Tab>HTML</Tab>
        </TabList>
        <TabPanel>
          <div className="my-2">
            <h1 className="text-3xl mb-2 mt-2">{guideData.guideTitle}</h1>
          </div>
          {/* Responsive grid setup */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {guideData.steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col space-y-2 bg-white rounded-lg shadow-lg overflow-hidden"
              >
                {/* Tailwind card for image */}
                <div className="bg-gray-100">
                  <img
                    src={step.screenshotUrl}
                    alt={`Step ${index + 1}`}
                    className="w-full h-auto object-cover" // Use object-cover for better aspect ratio handling
                  />
                </div>
                {/* Tailwind card for text */}
                <div className="p-4">
                  <h2 className="text-lg font-semibold">{step.stepTitle}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: step.stepDescription }}
                    className="prose" // Use Tailwind Typography plugin (prose) for better text formatting
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 p-6 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full max-w-lg mb-12 mt-12">
              <p className="text-lg font-semibold text-gray-700">
                HTML Copy Section
              </p>
              <textarea
                readOnly
                value={htmlString}
                className="mt-12 mb-12 w-full h-64 p-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              ></textarea>
            </div>
            <button
              onClick={() => copyToClipboard(htmlString)}
              className="px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded transition-colors duration-150 ease-in-out focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Copy HTML
            </button>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default GuideHTML;
