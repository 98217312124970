import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiSettings, FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FiMenu, FiX, FiSearch } from "react-icons/fi";
import { BsLightningFill } from "react-icons/bs";
import { FeaturedMain } from "../constants/featuredMain";
import { useNavigate } from "react-router-dom";
import {
  FaBlog,
  FaYoutube,
  FaMicrophone,
  FaYoutubeSquare,
  FaImages,
  FaPencilAlt,
  FaClipboard,
  FaChrome,
  FaQrcode,
  FaFilm,
  FaBook,
  FaGraduationCap,
  FaComments,
  FaCompass,
} from "react-icons/fa";
import { NODE_ENV } from "../constants/config";

function Sidebar({ activeTab, onTabChange }) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openItems, setOpenItems] = useState({}); // to keep track of which items are open
  const [tabIndex, setTabIndex] = useState(activeTab || 0); // Initialize with activeTab

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const searchInputRef = useRef(null);
  const hideme = false;

  const navigate = useNavigate();

  const onGuideSelect = (guide) => {
    navigate(
      `/view?id=${guide.recordingSessionId}&source=server&uid=${guide.uid}`
    );
  };

  useEffect(() => {
    if (activeTab !== undefined) {
      setTabIndex(activeTab); // Update tab index when activeTab changes
    }
  }, [activeTab]);

  // New state variables for tag hierarchy and open tags
  const [tagHierarchy, setTagHierarchy] = useState({});
  const [openTags, setOpenTags] = useState({});

  // Update openTags based on current guide
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uid = searchParams.get("uid");
    const guideId = searchParams.get("id");

    if (location.pathname.startsWith("/view") && uid && guideId) {
      // Find the guide in the Featured data
      const currentGuide = FeaturedMain.find(
        (guide) => guide.uid === uid && guide.recordingSessionId === guideId
      );

      if (currentGuide) {
        // Set tabIndex to Guides tab
        setTabIndex(2);

        // Expand all tags associated with the guide
        const newOpenTags = {};
        currentGuide.tags.forEach((tag) => {
          newOpenTags[`#${tag}`] = true; // Ensure the tag is in the format "#TagName"
        });
        setOpenTags(newOpenTags);

        // Optionally, highlight the current guide
        // Not necessary here since we're checking in renderHierarchy
      }
    }
  }, [location]);

  useEffect(() => {
    const hierarchy = {};
    FeaturedMain.forEach((guide) => {
      guide.tags.forEach((tag) => {
        if (!hierarchy[tag]) {
          hierarchy[tag] = [];
        }
        hierarchy[tag].push(guide);
      });
    });
    setTagHierarchy(hierarchy);
  }, []);

  const toggleTag = (tag) => {
    setOpenTags((prev) => ({
      ...prev,
      [tag]: !prev[tag],
    }));
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  const slugify = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  // State to hold the randomized guides
  const [randomGuides, setRandomGuides] = useState([]);

  // Shuffle the guides when the component mounts

  const prepareHierarchyData = useCallback(
    (data, parentPath = "", depth = 0) => {
      return data.map((item) => {
        const path = `${parentPath}/${slugify(item.title)}`;
        const newItem = {
          ...item,
          path,
          depth,
        };
        if (item.children && item.children.length > 0) {
          newItem.children = prepareHierarchyData(
            item.children,
            path,
            depth + 1
          );
        }
        return newItem;
      });
    },
    []
  );

  const my = [
    {
      title: "Create Custom ChatGPT",
      path: "/chat",
      icon: (
        <FaComments
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Create Video Tutorials",
      path: "/ai",
      icon: (
        <FaYoutube
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },

    {
      title: "Create How-To Guides ",
      path: "/guide",
      icon: (
        <FaCompass
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Convert Blog to Video", // New Entry
      path: "/blog-to-video", // New Path
      icon: (
        <FaBlog
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Install Extension ",
      path: "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
      icon: (
        <FaChrome
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },

    {
      title: "My Videos",
      path: "/myvideo",
      icon: (
        <FaFilm
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "My Guides",
      path: "/myguide",
      icon: (
        <FaBook
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Create Professional Audio",
      path: "/audio",
      icon: (
        <FaMicrophone
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },

    {
      title: "Create Video Thumbnail",
      path: "/tc",
      icon: (
        <FaImages
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Create Video Logo",
      path: "/logo",
      icon: (
        <FaPencilAlt
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
    {
      title: "Create Video Banner",
      path: "/banner",
      icon: (
        <FaClipboard
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },

    {
      title: "Create Video QR Code",
      path: "/qr",
      icon: (
        <FaQrcode
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },

    {
      title: "Training Videos",
      path: "/training",
      icon: (
        <FaGraduationCap
          className="inline-block text-4xl"
          style={{ color: "#00FFD1" }}
        />
      ),
    },
  ];

  const [hierarchyData, setHierarchyData] = useState(() =>
    prepareHierarchyData([])
  );

  useEffect(() => {
    const checkPathInData = (data) => {
      for (const item of data) {
        if (location.pathname.includes(item.path)) {
          return true;
        }
        if (item.children && checkPathInData(item.children)) {
          return true;
        }
      }
      return false;
    };

    if (checkPathInData(hierarchyData)) {
      setTabIndex(1);
    } else if (checkPathInData(my)) {
      setTabIndex(0);
    }
  }, [hierarchyData, location.pathname]);

  const fetchData = useCallback(async () => {
    const dataFile = NODE_ENV === "development" ? "/data5.json" : "/data.json";

    const res = await fetch(dataFile);
    const data = await res.json();
    const preparedData = prepareHierarchyData(data);
    setHierarchyData(preparedData);
    localStorage.setItem("hierarchyData", JSON.stringify(preparedData));
    localStorage.setItem("unixtimestamp", Date.now().toString());
  }, [prepareHierarchyData]);

  useEffect(() => {
    const storedData = localStorage.getItem("hierarchyData");
    const storedTimestamp = localStorage.getItem("unixtimestamp");
    if (storedData && storedTimestamp) {
      setHierarchyData(JSON.parse(storedData));
    } else {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    const checkAndUpdateData = async () => {
      const storedTimestamp = localStorage.getItem("unixtimestamp");
      const serverTimeRes = await fetch("/data_time.json");
      const { unixtimestamp: serverTime, forceUpdate } =
        await serverTimeRes.json();

      if (forceUpdate || !storedTimestamp || serverTime > storedTimestamp) {
        fetchData();
      } else {
        const storedData = localStorage.getItem("hierarchyData");
        if (storedData) {
          setHierarchyData(JSON.parse(storedData));
        } else {
          fetchData();
        }
      }
    };
    checkAndUpdateData();
  }, [fetchData]);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const expandedItems = {};

    let currentPath = "";
    pathSegments.forEach((segment, index) => {
      currentPath += `/${segment}`;
      const item = hierarchyData.find((data) =>
        transformForComparison(data.path).startsWith(
          transformForComparison(currentPath)
        )
      );
      if (item) {
        expandedItems[item.title] = true;

        if (index === pathSegments.length - 1 && item.children) {
          item.children.forEach((child) => {
            expandedItems[child.title] = true;
          });
        }
      }
    });

    setOpenItems(expandedItems);
  }, [location.pathname, hierarchyData]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleOpen = useCallback((item, open) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [item.title]: open !== prevOpenItems[item.title] ? open : false,
    }));
  }, []);

  // Adjusted renderHierarchy function
  const renderDataHierarchy = (data, depth = 0) => {
    return (
      <ul className="pl-0">
        {data.map((item, index) => {
          const isOpen = openItems[item.title];
          const isItemSelected = location.pathname === item.path;
          const hasChildren = item.children && item.children.length > 0;

          return (
            <li
              key={index}
              className="bg-gray-800 p-2 mb-4 mt-4 rounded-lg text-white"
            >
              <div className="flex items-center">
                {hasChildren ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => toggleOpen(item, !isOpen)}
                  >
                    {isOpen ? (
                      <FiChevronDown className="text-white mr-2" />
                    ) : (
                      <FiChevronRight className="text-white mr-2" />
                    )}
                  </div>
                ) : (
                  <span className="inline-block w-6"></span>
                )}
                <Link
                  to={item.path}
                  className={`px-4 py-2 rounded-md ${
                    isItemSelected
                      ? "bg-gray-600 text-white"
                      : "text-cyan-300 hover:bg-gray-900"
                  }`}
                  style={{ fontSize: `${1.0 - depth * 0.1}rem` }}
                >
                  {item.title}
                </Link>
              </div>
              {isOpen && hasChildren && (
                <ul className="pl-4">
                  {renderDataHierarchy(item.children, depth + 1)}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderTagHierarchy = (data, depth = 0) => {
    // Extract current UID and ID from the URL
    const searchParams = new URLSearchParams(location.search);
    const currentUid = searchParams.get("uid");
    const currentId = searchParams.get("id");

    return (
      <ul className="pl-0">
        {data.map((item, index) => {
          const isOpen = openTags[item.title];
          const hasChildren = item.children && item.children.length > 0;
          const isGuide = item.guide != null;

          // Define isItemSelected based on current UID and ID
          const isItemSelected =
            isGuide &&
            item.guide.uid === currentUid &&
            item.guide.recordingSessionId === currentId;

          return (
            <li
              key={index}
              className="bg-gray-800 p-2 mb-4 mt-4 rounded-lg text-white"
            >
              <div className="flex items-center">
                {hasChildren ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => toggleTag(item.title)}
                  >
                    {isOpen ? (
                      <FiChevronDown className="text-white mr-2" />
                    ) : (
                      <FiChevronRight className="text-white mr-2" />
                    )}
                  </div>
                ) : (
                  <span className="inline-block w-6"></span>
                )}
                {isGuide ? (
                  <div
                    className={`px-4 py-2 rounded-md cursor-pointer ${
                      isItemSelected
                        ? "bg-gray-600 text-white"
                        : "text-cyan-300 hover:bg-gray-900"
                    }`}
                    style={{ fontSize: `${1.0 - depth * 0.1}rem` }}
                    onClick={() => handleGuideSelect(item.guide)}
                  >
                    {item.title}
                  </div>
                ) : (
                  <div
                    className="px-4 py-2 rounded-md text-cyan-300 hover:bg-gray-900 cursor-pointer"
                    style={{ fontSize: `${1.0 - depth * 0.1}rem` }}
                    onClick={() => {
                      const tagWithoutHash = item.title.startsWith("#")
                        ? item.title.slice(1)
                        : item.title;
                      navigate(`/tag/${tagWithoutHash}`);
                    }}
                  >
                    {item.title}
                  </div>
                )}
              </div>
              {isOpen && hasChildren && (
                <ul className="pl-4">
                  {renderTagHierarchy(item.children, depth + 1)}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  // Build tagHierarchyData for rendering
  const tagHierarchyData = Object.keys(tagHierarchy).map((tag) => ({
    title: `#${tag}`,
    children: tagHierarchy[tag].map((guide) => ({
      title: guide.guideTitle,
      guide,
    })),
  }));

  const transformForComparison = (string) =>
    string.toLowerCase().replace(/ /g, "-");

  const filterHierarchyData = useCallback(() => {
    if (searchTerm.length < 3) {
      setFilteredData([]);
      return;
    }

    const filterData = (data) => {
      return data
        .flatMap((item) => {
          if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
          }
          if (item.children) {
            return filterData(item.children);
          }
          return [];
        })
        .filter(Boolean);
    };

    const results = [...filterData([]), ...filterData(hierarchyData)];
    setFilteredData(results);
  }, [searchTerm, hierarchyData]);

  useEffect(() => {
    filterHierarchyData();
  }, [searchTerm, filterHierarchyData]);

  const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleGuideSelect = (guide) => {
    onGuideSelect(guide);
  };

  const groupByFirstPathElement = (data) => {
    return data.reduce((acc, item) => {
      if (item.path) {
        const firstPathElement = item.path.split("/")[1];
        if (!acc[firstPathElement]) {
          acc[firstPathElement] = [];
        }
        acc[firstPathElement].push(item);
      }
      return acc;
    }, {});
  };

  const renderSearchResults = (data) => {
    const groupedData = groupByFirstPathElement(data);

    return (
      <div className="space-y-2 ">
        {Object.entries(groupedData).map(([key, items]) => (
          <div key={key} className="space-y-2">
            <span className="text-gray-700 font-bold">
              {toSentenceCase(key)}
            </span>
            <ul className="list-disc pl-5 space-y-2">
              {items
                .filter((item) => item.path && item.path.length > 2)
                .map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <Link
                      to={item.path}
                      className="px-4 py-2 rounded-md text-gray-800 hover:bg-gray-300"
                    >
                      {toSentenceCase(item.title)}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  // New function to render 'my' items as per your requirements
  const renderMyItems = () => {
    return (
      <div className="space-y-2 ">
        {my.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className="flex flex-col items-center p-2 m-2 bg-gray-800 rounded-lg "
          >
            <div className="mb-2 mt-2">{item.icon}</div>
            <div className="text-sm  text-white">{item.title}</div>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="flex ">
        <button
          onClick={toggleSidebar}
          className="bg-gray-400 text-white p-2 rounded-md shadow-md md:hidden p-2 m-2"
        >
          {sidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>
      <div
        className={`sm:flex ${
          sidebarOpen ? "block sm:hidden" : "hidden"
        } bg-gray-300 dark:bg-gray-400`}
      >
        <div className="">
          {hideme && (
            <div className="p-1 flex flex-col w-64 bg-gray-200">
              <div className="flex items-center relative">
                <input
                  type="text"
                  ref={searchInputRef}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  className="border bg-gray-200 p-2 flex-grow pl-10"
                />
                {hideme && searchTerm ? (
                  <FiX
                    onClick={() => setSearchTerm("")}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    size={24}
                  />
                ) : (
                  <FiSearch
                    onClick={() => searchInputRef.current.focus()}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                    size={24}
                  />
                )}
              </div>
              {hideme && searchTerm && searchTerm.length >= 3 && (
                <div className="mt-2 p-4 bg-gray-200 border rounded-md shadow">
                  <h3 className="text-gray-600 mb-2">Search Results:</h3>
                  {filteredData.length > 0 ? (
                    renderSearchResults(filteredData)
                  ) : (
                    <div className="text-gray-500">Nothing here yet...</div>
                  )}
                </div>
              )}
            </div>
          )}

          <nav
            className={`${
              sidebarOpen || window.innerWidth > 640
                ? "flex flex-col w-64 h-full bg-gray-200 shadow-md py-2 px-2 space-y-4 transition-all duration-300"
                : "hidden md:flex md:flex-col md:w-64 md:h-full md:bg-gray-200 md:shadow-md md:py-4 md:px-6 md:space-y-4 transition-all duration-300"
            }`}
          >
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => {
                setTabIndex(index); // Update the local state to reflect the new tab index
                if (onTabChange) {
                  onTabChange(index);
                }
              }}
            >
              <TabList>
                <Tab>
                  <div className="flex items-center">Tools</div>
                </Tab>
                <Tab>
                  <div className="flex items-center">Videos </div>
                </Tab>
                <Tab>
                  <div className="flex items-center">Guides </div>
                </Tab>
              </TabList>

              <TabPanel>{renderMyItems()}</TabPanel>
              <TabPanel>
                <div className="space-y-2">
                  {renderDataHierarchy(
                    hierarchyData.map((item) => {
                      if (item.title === "College") {
                        return {
                          ...item,
                          isExpanded: true,
                        };
                      }
                      return item;
                    })
                  )}
                </div>
              </TabPanel>

              <TabPanel>
                <div className="space-y-2">
                  {renderTagHierarchy(tagHierarchyData)}
                </div>
              </TabPanel>

              <div className="mt-auto">
                <Link
                  to="/subscribe"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-300 rounded-md"
                >
                  <BsLightningFill className="inline-block mr-2" />
                  Subscribe
                </Link>
              </div>
            </Tabs>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
