import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../UserContext"; // import UserContext
import { AuthContext } from "../AuthProvider";
import { Link } from "react-router-dom";
import {
  FaCaretDown,
  FaUser,
  FaGraduationCap,
  FaYoutube,
  FaHashtag,
} from "react-icons/fa"; // Import the FaGraduationCap icon from react-icons
import { BsLightningFill } from "react-icons/bs";
import { RainbowButton } from "./Rainbow"; // Adjust the import path as necessary
import {
  MdOutlineAutoAwesome,
  MdOutlinePlayCircleFilled,
} from "react-icons/md"; // Import video play icon
import { KimaviBrand } from "./KimaviBrand";
import { toast } from "react-toastify";

function Navbar({ onLoginClick, handleSignOut, resetSidebarTab }) {
  const [dropdownStates, setDropdownStates] = useState({
    courseDropdownOpen: false,
    appsDropdownOpen: false,
    learnDropdownOpen: false,
    userDropdownOpen: false,
  });

  const { user, isPaid, idToken } = useContext(AuthContext);
  const appsDropdownRef = useRef(null);
  const learnDropdownRef = useRef(null);
  const courseDropdownRef = useRef(null);
  const userDropdownRef = useRef(null);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [serverDate, setServerDate] = useState(null);
  const [localCacheDate, setLocalCacheDate] = useState(new Date());

  const CourseLinks = [
    { name: "College", path: "/college" },
    { name: "Subscribe", path: "/subscribe" },
  ];

  const appsLinks = [
    { name: "Create Chat", path: "/chat" },
    { name: "Create Video", path: "/ai" },
    { name: "Create Guide", path: "/guide" },
    { name: "Blog to Video", path: "/blog-to-video" },
    {
      name: "Install Extension",
      path: "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
    },
    { name: "Create Audio", path: "/audio" },
    { name: "Create Thumbnail", path: "/tc" },
    { name: "Create Logo", path: "/logo" },
    { name: "Create Banner", path: "/banner" },
    { name: "Create QR Code", path: "/qr" },

    { name: "Subscribe", path: "/subscribe" },
    { name: "My Videos", path: "/myvideo" },
    { name: "My Guides", path: "/myguide" },
  ];

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleOutsideClick = (event, ref, dropdown) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownStates((prevState) => ({
        ...prevState,
        [dropdown]: false,
      }));
    }
  };

  useEffect(() => {
    const handleOutsideClicks = (event) => {
      handleOutsideClick(event, appsDropdownRef, "appsDropdownOpen");
      handleOutsideClick(event, learnDropdownRef, "learnDropdownOpen");
      handleOutsideClick(event, courseDropdownRef, "courseDropdownOpen");
      handleOutsideClick(event, userDropdownRef, "userDropdownOpen");
    };

    if (Object.values(dropdownStates).some((isOpen) => isOpen)) {
      document.addEventListener("click", handleOutsideClicks);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClicks);
    };
  }, [dropdownStates]);

  const fetchServerDate = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const date = new Date();
        resolve({ date });
      }, 1000);
    });
  };

  useEffect(() => {
    const getServerDate = async () => {
      const response = await fetchServerDate();
      setServerDate(new Date(response.date));
    };
    getServerDate();
  }, []);

  useEffect(() => {
    if (forceUpdate || (serverDate && serverDate > localCacheDate)) {
      if (forceUpdate) {
        toast.success("Cache updated successfully");
      }
      setLocalCacheDate(serverDate || new Date());
      setForceUpdate(false);
    }
  }, [serverDate, forceUpdate]);

  return (
    <nav
      id="navbar"
      className="bg-gray-200 dark:bg-gray-800 py-2 items-center "
    >
      <div className="container px-3 md:px-3 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* Logo as card */}

          {/* Kimavi brand with icon */}
          <div className="flex items-center  rounded">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation behavior
                window.location.href = "/"; // Force navigation to root
              }}
              className="flex items-center"
            >
              <img
                src="/images/logo.png"
                alt="Kimavi Logo"
                className="w-20 h-20"
              />
            </Link>
          </div>
        </div>

        {/* Icons */}
        <div className="flex items-center space-x-2 md:space-x-4">
          {/* User Dropdown */}
          <div
            ref={userDropdownRef}
            className="relative inline-flex items-center"
          >
            <span
              className={`px-2 py-1 rounded ${
                user ? "text-blue-700" : "text-gray-400"
              }`}
              onClick={() => toggleDropdown("userDropdownOpen")}
            >
              <RainbowButton>
                <FaUser size={24} />
              </RainbowButton>
            </span>
            {dropdownStates.userDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {user ? (
                  <li>
                    <button
                      onClick={() => {
                        handleSignOut();
                        toggleDropdown("userDropdownOpen");
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500"
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      onClick={() => {
                        onLoginClick();
                        toggleDropdown("userDropdownOpen");
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500"
                    >
                      Login
                    </button>
                  </li>
                )}

                <li>
                  <Link
                    to="/myvideo"
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500"
                  >
                    My Videos
                  </Link>
                </li>

                <li>
                  <Link
                    to="/myguide"
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500"
                  >
                    My Guides
                  </Link>
                </li>

                <li>
                  <button
                    onClick={() => setForceUpdate(true)}
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500"
                  >
                    Update Cache
                  </button>
                </li>
              </ul>
            )}
          </div>

          <div className="relative inline-flex items-center">
            <Link to="/tags">
              <RainbowButton>
                <FaHashtag size={24} />
              </RainbowButton>
            </Link>
          </div>

          {/* Course Dropdown */}
          <div
            ref={courseDropdownRef}
            className="relative inline-flex items-center"
          >
            <span onClick={() => toggleDropdown("courseDropdownOpen")}>
              <RainbowButton>
                <FaGraduationCap size={24} />
              </RainbowButton>
            </span>
            {dropdownStates.courseDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {CourseLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={() => toggleDropdown("courseDropdownOpen")}
                      className="block px-4 py-2 text-sm hover:bg-blue-500"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Apps Dropdown */}
          <div
            ref={appsDropdownRef}
            className="relative inline-flex items-center"
          >
            <span onClick={() => toggleDropdown("appsDropdownOpen")}>
              <RainbowButton>
                <BsLightningFill size={24} />
              </RainbowButton>
            </span>
            {dropdownStates.appsDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1 right-[15px]">
                {appsLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={() => toggleDropdown("appsDropdownOpen")}
                      className="block px-4 py-2 text-sm hover:bg-blue-500"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
