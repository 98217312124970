import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

function AnalyticsLogger() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  return null; // This component does not render anything to the DOM
}

export default AnalyticsLogger;
