import React from "react";
import { FaSpinner } from "react-icons/fa";

const Loader = () => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4 mt-4 mb-4">
      <h1>Please be patient...</h1>
      <FaSpinner className="animate-spin h-12 w-12 text-blue-500" />
      <h1 className=" p-2 m-2 ">
        AI can take more than a minute to respond...
      </h1>
      <h1 className=" p-2 m-2 ">For help email us at admin@kimavi.com</h1>
    </div>
  );
};

export default Loader;
