import styled from "styled-components";
const colors = ["#f44336", "#FFC107", "#f44336", "#ffc107"];
// background-image: linear-gradient(to right, #f44336, #FFC107, #f44336, #ffc107);
const RainbowText = styled.div`
  display: flex; // This ensures flex layout
  align-items: center; // This centers children vertically
  // Other styles remain unchanged
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    #5f9ea0,
    #204051,
    #5f9ea0,
    #008080
  );
  animation: rainbow 20s ease infinite;
  color: #ffffff;
  font-size: 24px;
  border-radius: 10px;
  text-align: left;
  padding: 20px;
  margin: 20px;
  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

// Assuming colors are defined above or you're using a direct gradient in the background-image
const RainbowButton = styled.button`
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    #5f9ea0,
    #204051,
    #5f9ea0,
    #008080
  );
  animation: rainbow 20s ease infinite;
  color: #ffffff;
  font-size: 16px; // Tailwind's font-bold likely corresponds to a font-weight rather than font-size
  font-weight: bold; // Assuming font-bold is desired
  padding: 8px 16px; // Tailwind's p-2 py-2 px-4 converted to pixels
  margin: 8px; // Tailwind's m-2 converted to pixels
  border: none;
  cursor: pointer;
  border-radius: 0.375rem; // Tailwind's rounded corresponds to a border-radius of 0.375rem
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s; // Smooth transform and background-color transition on hover
  width: auto; // Adjust based on content, or set a fixed width if necessary for consistency

  &:hover {
    background-color: #000000; // Example hover effect, replace with your desired effect
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const RainbowButton1 = styled.button`
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    #5f9ea0,
    #204051,
    #5f9ea0,
    #008080
  );
  animation: rainbow 20s ease infinite;
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  padding: 8px 16px;
  margin: 8px;
  border: none;
  cursor: pointer;
  border-radius: 0.375rem;
  display: inline-flex; // Changed from flex to inline-flex
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    background-color: #000000;
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const RainbowButtonHeader = styled.button`
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    #5f9ea0,
    #204051,
    #5f9ea0,
    #008080
  );
  animation: rainbow 20s ease infinite;
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  padding: 8px 16px;
  margin: 8px;
  border: none;
  cursor: pointer;
  border-radius: 0.375rem;
  display: inline-flex; // Changed from flex to inline-flex
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    background-color: #000000;
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
export { RainbowButton, RainbowText, RainbowButton1, RainbowButtonHeader };
