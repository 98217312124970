import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const GoRedirect = () => {
  const { name } = useParams();
  const goLinks = JSON.parse(localStorage.getItem("goLinks")) ?? [];

  useEffect(() => {
    const goLink = goLinks?.find((link) => link.goLinkName === name);
    if (goLink) {
      window.location.href = goLink.targetUrl;
    } else {
      // Redirect to a 404 page or show an error message
    }
  }, [name, goLinks]);

  return null;
};

export default GoRedirect;
