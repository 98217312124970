import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import { auth, db } from "./firebase";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; // For default styles
import { FaTimes } from "react-icons/fa";
import { signOut } from "firebase/auth";
import { FaGoogle } from "react-icons/fa"; // Import FaGoogle from react-icons/fa

import {
  doc,
  onSnapshot,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  sendEmailVerification,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { NODE_ENV } from "./constants/config";

const Login = ({ closeLogin }) => {
  const { user, userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signInError, setSignInError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [localSignedIn, setLocalSignedIn] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state for success message
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    // Automatically set localSignedIn to true if user is already signed in
    if (user) {
      setLocalSignedIn(true);
    }
  }, [user]);

  useEffect(() => {
    // Ensure userEmail and isPaid are not null or undefined
    if (userEmail && isPaid !== undefined) {
      const postSignInMessage = () => {
        const isLoginPage = window.location.pathname === "/login";
        const isCorrectOrigin =
          window.location.origin === "https://www.kimavi.com" ||
          window.location.origin === "http://localhost:3000";

        if (isLoginPage && isCorrectOrigin) {
          window.postMessage(
            {
              type: "signInMessage",
              data: {
                email: userEmail,
                status: isPaid ? "paid" : "unpaid",
                idToken: idToken,
              },
            },
            window.location.origin
          );
          // console.log("Sent signInMessage");
        }
      };

      postSignInMessage();
    }
  }, [userEmail, isPaid]); // Depend on userEmail and isPaid

  // Extracted function to post message

  const actionCodeSettings = {
    url: window.location.origin + "/login", // This will point to, for example, 'https://yourapp.com/my'
    handleCodeInApp: true,
  };

  // Function to verify reCAPTCHA token
  const verifyRecaptcha = async (token) => {
    // console.log("verifyRecaptcha");
    const apiBaseUrl =
      NODE_ENV === "development"
        ? "http://127.0.0.1:5001/json-storage-bed47/us-central1/api"
        : "https://us-central1-json-storage-bed47.cloudfunctions.net/api";

    try {
      const response = await fetch(`${apiBaseUrl}/verifyRecaptcha`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      // Parse the JSON response
      const responseData = await response.json();
      // console.log(responseData);

      // Check if reCAPTCHA verification was successful
      if (responseData.success) {
        toast.success("Welcome to Kimavi.....");
        return responseData;
      } else {
        toast.error("reCAPTCHA verification failed.");
        return { success: false };
      }
    } catch (error) {
      console.error("Error verifying reCAPTCHA", error);
      // toast.error("Error during reCAPTCHA verification.");
      return { success: false };
    }
  };

  const updateFirestoreUser = (user) => {
    const userDocRef = doc(collection(db, "users"), user.email); // Using modular SDK syntax

    // Check if the user document exists
    getDoc(userDocRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          // Prepare the object to update
          const updateData = {
            timeLogIn: Timestamp.now(), // Always update timeLogIn
          };

          // Check if displayName and photoURL have changed
          if (
            user.displayName &&
            user.displayName !== docSnapshot.data().displayName
          ) {
            updateData.displayName = user.displayName;
          }
          if (user.photoURL && user.photoURL !== docSnapshot.data().photoURL) {
            updateData.photoURL = user.photoURL;
          }

          // Update the document with new data
          return updateDoc(userDocRef, updateData);
        } else {
          // Create a new document with full user data
          const userData = {
            email: user.email,
            uid: user.uid,
            timeLogIn: Timestamp.now(),
            displayName: user.displayName,
            photoURL: user.photoURL,
          };

          return setDoc(userDocRef, userData); // Use setDoc to create a new document
        }
      })
      .then(() => {
        // Successfully updated or added user info to Firestore
        closeLogin();
      })
      .catch((error) => {
        console.error("Error updating Firestore:", error);
        // Handle error if the Firestore update fails
      });
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          updateFirestoreUser(result.user); // Call updateFirestoreUser here

          toast.success("Successfully signed in!");
          closeLogin();
        })
        .catch((error) => {
          console.error("Error during email link sign-in", error);
        });
    }
  }, []);

  const handleSendSignInLink = async () => {
    if (!email) {
      setEmailError("Email is required.");
      toast.error("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);

    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setEmailError("Check your email for the sign-in link.");
        setTimeout(() => setEmailError(""), 3000);
        toast.info("Check your email for the sign-in link!");
      })
      .catch((error) => {
        console.error("Error sending email sign-in link", error);

        switch (error.code) {
          case "auth/operation-not-allowed":
            toast.error(
              "Email link sign-in is not enabled. Please contact support."
            );
            break;
          default:
            toast.error("Error sending sign-in link. Please try again later.");
            break;
        }
      });
  };

  const signInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // You can access the signed-in user information from the 'result' object.
        const user = result.user;
        // Handle successful sign-in or other operations.
        toast.success("Welcome to Kimavi.....");
        updateFirestoreUser(user);

        closeLogin();
      })
      .catch((error) => {
        // Handle errors during Google sign-in.
        // toast.error("Error during Google sign-in. Please try again later.");
        console.error(error);
      });
  };

  const handleForgotPassword = () => {
    if (!email) {
      setResetPasswordError("Please enter your email address.");
      return;
    }
    if (!emailRegex.test(email)) {
      setResetPasswordError("Invalid email format.");
      return;
    }
    if (!email) {
      setResetPasswordError("Please enter your email address.");
      setTimeout(() => setResetPasswordError(""), 3000);
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetPasswordSuccess("Password reset email sent. Check your inbox.");
        setTimeout(() => setResetPasswordSuccess(""), 5000);
      })
      .catch((error) => {
        setResetPasswordError("Error sending password reset email.");
        setTimeout(() => setResetPasswordError(""), 3000);
        console.error(error);
      });
  };

  const signInWithEmail = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (!email || !password) {
      setEmailError("Please enter both email and password.");
      setTimeout(() => setEmailError(""), 3000);
      return;
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      setTimeout(() => setPasswordError(""), 3000);
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);
    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = response.user;
        updateFirestoreUser(user);
        if (!user.emailVerified && user.email !== "kalpittest@test.com") {
          // If the user's email isn't verified, you can display a message and sign them out, if desired.
          toast.info("Please verify your email to continue using Kimavi.");
          toast.info("For help please email us at admin@kimavi.com.");
          auth.signOut();
          return;
        }

        closeLogin();
      })
      .catch((error) => {
        setSignInError("Incorrect email or password.");
        setTimeout(() => setSignInError(""), 3000);
        console.error(error);
      });
  };

  const signUpWithEmail = async (e) => {
    // toast("Sign up is by invitation only.");
    // toast("Email us at Admin@kimavi.com to get an invitation.");

    e.preventDefault();

    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (!email || !password) {
      setEmailError("Please enter both email and password.");
      setTimeout(() => setEmailError(""), 3000);
      return;
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      setTimeout(() => setPasswordError(""), 3000);
      return;
    }

    const result = await verifyRecaptcha(recaptchaToken);
    if (!result.success) {
      toast.error("reCAPTCHA verification failed.");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        // Send verification email after successful sign-up
        const user = response.user;
        sendEmailVerification(user)
          .then(() => {
            toast.info(
              "Please verify your email. A verification link has been sent to your inbox."
            );
          })
          .catch((error) => {
            // Handle errors specific to sending email verification
            console.error(error);
            setSignInError(
              "Error sending verification email. Please try again."
            );
            setTimeout(() => setSignInError(""), 3000);
          });
        closeLogin();
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            setEmailError("This email address is already in use.");
            toast.info("Please sign in instead.");
            break;
          case "auth/invalid-email":
            setEmailError("The email address is not valid.");
            toast.info("Please enter a valid email address.");
            break;
          case "auth/operation-not-allowed":
            setSignInError("Email/password sign up is not enabled.");
            toast.info("Please sign in with Google instead.");
            break;
          case "auth/weak-password":
            setPasswordError(
              "Password is not strong enough. It should be at least 6 characters long."
            );
            toast.info("Please enter a stronger password.");
            break;
          default:
            setSignInError("Error during sign up. Please try again.");
            console.error(error);
            break;
        }

        setTimeout(() => {
          setEmailError("");
          setPasswordError("");
          setSignInError("");
        }, 3000);
      });
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // On successful sign out
        setLocalSignedIn(false);
        setShowSuccessMessage(false); // Optionally hide any success message
        toast.success("You have been signed out successfully.");
        // Optionally, add any additional logic post sign-out, like redirecting
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
        toast.error("An error occurred while signing out.");
        // Handle any errors during sign out
      });
  };

  return (
    <div className="flex items-center justify-center w-full h-1/2 bg-gray-400 p-4">
      {(showSuccessMessage || localSignedIn) && (
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <div className="text-green-600 text-xl font-bold text-center mb-4">
            Successfully signed in! Welcome.
          </div>
          <button
            onClick={handleSignOut}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Sign Out
          </button>
        </div>
      )}
      {!localSignedIn && (
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md relative">
          {closeLogin && (
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={closeLogin}
            >
              <FaTimes size={32} />
            </button>
          )}
          <div className="text-center text-2xl font-bold mb-4">
            Please Sign In
          </div>

          <Tabs>
            <TabList>
              <Tab>Google </Tab>
            </TabList>
            <TabPanel>
              <div className="mt-4 mb-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-4 mb-4 py-2 px-4 rounded inline-flex items-center"
                  type="button"
                  onClick={signInWithGoogle}
                >
                  <FaGoogle className="mr-2" /> Sign In with Google
                </button>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default Login;
