import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthProvider";
import Hero from "./components/Hero";
import FeaturedGuide from "./components/FeaturedGuide";
import { Featured } from "./constants/featured";
import Sidebar from "./components/Sidebar";
import PreviewGuide from "./PreviewGuide";
import { useNavigate } from "react-router-dom";
import useAppLocation from "./useAppLocation";
import FeaturedYouTubeVideo from "./components/FeaturedYouTube"; // Adjust the import path
import { gradientOptions } from "./gradientOptions"; // Adjust path as needed
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ChatBot from "./chatbot";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const rainbowColors = gradientOptions.find(
  (option) => option.name === "Rainbow"
).colors;
const rainbowGradient = `linear-gradient(90deg, ${rainbowColors.join(", ")})`;

function HomeLayout() {
  const handleGuideSelect = (guide) => {
    navigate(
      `/view?id=${guide.recordingSessionId}&source=server&uid=${guide.uid}`
    );
  };

  const guidesPerPage = 3; // Or any number you want to display per page
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleGuides, setVisibleGuides] = useState(
    Featured.slice(0, guidesPerPage)
  );

  const navigate = useNavigate();
  const { userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const { shouldRenderSidebar } = useAppLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [guideData, setGuideData] = useState(null);
  const [isExploreTabActive, setIsExploreTabActive] = useState(false);

  // State for rotating messages
  const [learningMessageIndex, setLearningMessageIndex] = useState(0);
  const [videoMessageIndex, setVideoMessageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState(""); // Animation class state

  // Arrays of messages to rotate
  const learningMessages = [
    "Edu Tutorials",
    "How To Tutorials",
    "Mobile Tutorials",
  ];

  const videoMessages = [
    "⚡ Customer ChatGPT - Made Easy ⚡",
    "⚡ How To Guides  - Made Easy ⚡",
    "⚡ Video Tutorials - Made Easy ⚡",
    "⚡ AI Analytics- Made Easy ⚡",

    "💡 Customer ChatGPT - Made Better 💡",
    "💡 How To Guides  - Made Better 💡",
    "💡 Video Tutorials - Made Better 💡",
    "💡 AI Analytics- Made Better 💡",

    "🎉 Customer ChatGPT - Made Joyful 🎉",
    "🎉 How To Guides  - Made Joyful 🎉",
    "🎉 Video Tutorials - Made Joyful 🎉",
    "🎉 AI Analytics- Made Joyful 🎉",
  ];

  // Add CSS animations in the head of the document
  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @keyframes slideInUp {
        0% {
          opacity: 0;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .fade-out {
        animation: fadeOut 1s forwards;
      }

      .animate-slide-in-up {
        animation: slideInUp 1s ease-out forwards;
      }
    `;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  useEffect(() => {
    const shuffledGuides = shuffleArray(Featured);
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage));
  }, []);

  const handleRefresh = () => {
    const shuffledGuides = shuffleArray(Featured);
    setCurrentIndex(0);
    setVisibleGuides(shuffledGuides.slice(0, guidesPerPage));
  };

  const handleNext = () => {
    const newIndex =
      currentIndex + guidesPerPage < Featured.length
        ? currentIndex + guidesPerPage
        : currentIndex;
    setCurrentIndex(newIndex);
    setVisibleGuides(Featured.slice(newIndex, newIndex + guidesPerPage));
  };

  const handlePrev = () => {
    const newIndex =
      currentIndex - guidesPerPage >= 0 ? currentIndex - guidesPerPage : 0;
    setCurrentIndex(newIndex);
    setVisibleGuides(Featured.slice(newIndex, newIndex + guidesPerPage));
  };

  // Effect to rotate learning messages every 4 seconds with fade out and fade in
  useEffect(() => {
    const learningInterval = setInterval(() => {
      // Trigger fade out animation
      setAnimationClass("fade-out");

      // Wait for fade out to finish, then change message and fade in
      setTimeout(() => {
        setLearningMessageIndex(
          (prevIndex) => (prevIndex + 1) % learningMessages.length
        );
        setAnimationClass("animate-slide-in-up");
      }, 1000); // 1 second delay for fade out before switching content
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(learningInterval);
  }, []);

  // Effect to rotate video messages every 5 seconds with fade out and fade in
  useEffect(() => {
    const videoInterval = setInterval(() => {
      // Trigger fade out animation
      setAnimationClass("fade-out");

      // Wait for fade out to finish, then change message and fade in
      setTimeout(() => {
        setVideoMessageIndex(
          (prevIndex) => (prevIndex + 1) % videoMessages.length
        );
        setAnimationClass("animate-slide-in-up");
      }, 1000); // 1 second delay for fade out before switching content
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(videoInterval);
  }, []);

  useEffect(() => {
    if (selectedGuide) {
      const { uid, recordingSessionId } = selectedGuide;
      navigate(`/video?id=${recordingSessionId}&source=server&uid=${uid}`);
    }
  }, [selectedGuide]);

  const handleTabChange = (tabIndex) => {
    setIsExploreTabActive(tabIndex === 1);
  };

  return (
    <div className="grid grid-cols-1 md:flex md:grid-cols-none h-full w-full">
      {shouldRenderSidebar && isSidebarOpen && (
        <Sidebar
          onGuideSelect={setSelectedGuide}
          onTabChange={handleTabChange}
        />
      )}

      <div className="flex flex-col m-2 p-2 w-full">
        {guideData && isExploreTabActive ? (
          <PreviewGuide guideData={guideData} />
        ) : (
          <>
            {/* Learning Section with Rotating Messages */}
            <section
              className="bg-gradient-to-b from-blue-50 to-gray-100 py-12 rounded-2xl"
              style={{
                background: rainbowGradient,
                backgroundSize: "400% 400%",
                animation: "rainbowAnimation 15s ease infinite",
              }}
            >
              <div className="container mx-auto space-y-12 px-6 md:px-12">
                {/* Hero Section */}
                <div className="text-center">
                  <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-50 mb-8  ">
                    Chat, Guides & Videos - Drive Customer Success
                  </h2>
                  <h3 className="text-sm md:text-xl lg:text-2xl  text-gray-100 mb-8">
                    Increase Customer Success and Productivity Gains by 10X
                  </h3>
                  <div
                    className={`mb-8 text-2xl md:text-3xl lg:text-4xl text-[#00FFFF] px-3 py-3 bg-black rounded-2xl inline-block ${animationClass}`}
                  >
                    {videoMessages[videoMessageIndex]}
                  </div>
                  <h3 className="text-sm md:text-xl lg:text-2xl  text-gray-200 mb-8">
                    Custom ChatBot, In-App Guides and How To Videos - Built on
                    AI - For Customer Success
                  </h3>
                  <div className="bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                    <FeaturedGuide />
                  </div>
                </div>

                <div className="flex flex-col m-2 p-2 w-full">
                  {/* YouTube Video Section */}
                  <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
                    <div className="container mx-auto space-y-12 px-6 md:px-12">
                      <div className="text-center">
                        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-10">
                          Try Our ChatBot - Trained on Kimavi Products
                        </h2>
                        <div className="mt-5 mb-5 p-3 m-3 bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                          <div className="bg-gray-200 flex justify-center m-3 p-3">
                            <iframe
                              src="/iframe-embed?botId=kimavi-101"
                              width="400"
                              height="600"
                              title="Kimavi ChatBot"
                              style={{ border: "none" }}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="flex flex-col m-2 p-2 w-full">
                  {/* YouTube Video Section */}
                  <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
                    <div className="container mx-auto space-y-12 px-6 md:px-12">
                      <div className="text-center">
                        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-800 mb-8">
                          How To Guides - Examples
                        </h2>
                        <div className="bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                          <FeaturedYouTubeVideo />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                {/* Explore Video Tutorials Section */}
                <div className="text-center">
                  <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-100 mb-8">
                    Video Tutorials - Examples
                  </h2>
                  <div
                    className={`mb-4 text-2xl md:text-3xl lg:text-4xl text-[#00FFFF] px-3 py-2 bg-black rounded inline-block ${animationClass}`}
                  >
                    {learningMessages[learningMessageIndex]}
                  </div>
                  <div className="mb-4">
                    <div className="bg-white rounded-lg shadow-lg p-2 hover:shadow-xl transition-shadow duration-300 mt-10">
                      <Hero />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container mx-auto px-4 text-center mb-6 mt-6  ">
              <h2 className="text-xl font-bold text-gray-800 mb-4">
                Featured How-To Guides
              </h2>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <button
                  onClick={handlePrev}
                  aria-label="Previous guides"
                  disabled={currentIndex === 0}
                  className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
                    currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <MdArrowBackIos className="text-gray-800" />
                </button>
                <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 overflow-hidden justify-center mb-4 md:mb-0">
                  {visibleGuides.map((guide, index) => (
                    <div
                      key={index}
                      className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl mb-4 md:mb-0"
                      onClick={() => handleGuideSelect(guide)}
                    >
                      <div className="p-8">
                        <a
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
                        >
                          {guide.guideTitle}
                        </a>
                        <p className="mt-2 text-gray-500">
                          #{guide.tags.join(" #")}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={handleNext}
                  aria-label="Next guides"
                  disabled={currentIndex + guidesPerPage >= Featured.length}
                  className={`rounded-full m-2 p-2 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ${
                    currentIndex + guidesPerPage >= Featured.length
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  <MdArrowForwardIos className="text-gray-800" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HomeLayout;
