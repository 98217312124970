import { useLocation } from "react-router-dom";

const useAppLocation = () => {
  const location = useLocation();

  const shouldRenderSidebar = () => {
    const appRoutes = ["/go", "/okr", "/gif", "/video"]; // Add other app routes here
    return appRoutes.includes(location.pathname);
  };

  return { shouldRenderSidebar };
};

export default useAppLocation;
