export const gradientOptions = [
  {
    id: 1,
    name: "Rainbow",
    colors: [
      "#FF0000",
      "#FFA500",
      "#FFFF00",
      "#008000",
      "#0000FF",
      "#4B0082",
      "#EE82EE",
    ],
  },
  { id: 2, name: "Sunset", colors: ["#ff7e5f", "#feb47b"] },
  { id: 3, name: "Ocean Blue", colors: ["#2193b0", "#6dd5ed"] },
  { id: 4, name: "Mango", colors: ["#ffe259", "#ffa751"] },
  { id: 5, name: "Reef", colors: ["#00d2ff", "#3a7bd5"] },
  { id: 6, name: "Flare", colors: ["#f12711", "#f5af19"] },
  { id: 7, name: "Sublime Light", colors: ["#FC5C7D", "#6A82FB"] },
  {
    id: 8,
    name: "Soft Sunrise",
    colors: ["#ffedd5", "#ffe4e6"],
  },
  {
    id: 9,
    name: "Pale Sky",
    colors: ["#e0f7fa", "#ffffff"],
  },
  {
    id: 10,
    name: "Mint Green",
    colors: ["#d4fc79", "#96e6a1"],
  },
  {
    id: 11,
    name: "Lavender Dream",
    colors: ["#e1eec3", "#f05053"],
  },
  {
    id: 12,
    name: "Soft Horizon",
    colors: ["#c9ffbf", "#ffafbd"],
  },
  {
    id: 13,
    name: "Blush",
    colors: ["#f3e5f5", "#e1bee7"],
  },
  {
    id: 14,
    name: "Subtle Ocean",
    colors: ["#d7f2ba", "#84fab0"],
  },
  {
    id: 15,
    name: "Dark Rainbow",
    colors: ["#4a148c", "#d500f9", "#f44336", "#ffeb3b", "#4caf50"],
  },
  {
    id: 16,
    name: "Pastel Rainbow",
    colors: ["#ff9a9e", "#fecfef", "#a1c4fd", "#c2e9fb", "#d4fc79"],
  },
  {
    id: 17,
    name: "Black to White",
    colors: ["#000000", "#ffffff"],
  },
  {
    id: 18,
    name: "Shades of Grey",
    colors: ["#333333", "#777777", "#bbbbbb"],
  },
  {
    id: 19,
    name: "Midnight",
    colors: ["#232526", "#414345"],
  },
  {
    id: 20,
    name: "Warm Grey",
    colors: ["#8e9eab", "#eef2f3"],
  },
  {
    id: 21,
    name: "Light Grey to White",
    colors: ["#d3d3d3", "#ffffff"],
  },
  // New added gradients
  {
    id: 22,
    name: "Pink Sky",
    colors: ["#ff9a9e", "#fad0c4"],
  },
  {
    id: 23,
    name: "Lavender Fields",
    colors: ["#b3ffab", "#12fff7"],
  },
  {
    id: 24,
    name: "Deep Purple",
    colors: ["#673AB7", "#512DA8"],
  },
  {
    id: 25,
    name: "Hot Pink",
    colors: ["#ff6f91", "#ff9671"],
  },
  {
    id: 26,
    name: "Cool Lavender",
    colors: ["#c3cfe2", "#e2e2e2"],
  },
  {
    id: 27,
    name: "Muted Pink",
    colors: ["#fdd5b1", "#ee9ca7"],
  },
  {
    id: 28,
    name: "Grey Sky",
    colors: ["#bdc3c7", "#2c3e50"],
  },
  {
    id: 29,
    name: "Rose",
    colors: ["#f953c6", "#b91d73"],
  },
  {
    id: 30,
    name: "Soft Pink to Grey",
    colors: ["#f7cac9", "#d5d6d6"],
  },
  {
    id: 31,
    name: "Violet",
    colors: ["#8a2be2", "#8a2be2"],
  },
  {
    id: 32,
    name: "Indigo",
    colors: ["#4b0082", "#4b0082"],
  },
  {
    id: 33,
    name: "Blue",
    colors: ["#0000ff", "#0000ff"],
  },
  {
    id: 34,
    name: "Green",
    colors: ["#00ff00", "#00ff00"],
  },
  {
    id: 35,
    name: "Yellow",
    colors: ["#ffff00", "#ffff00"],
  },
  {
    id: 36,
    name: "Orange",
    colors: ["#ff7f00", "#ff7f00"],
  },
  {
    id: 37,
    name: "Red",
    colors: ["#ff0000", "#ff0000"],
  },
];
