import dedent from "dedent";

const knownQA = [
  {
    question: "What is Kimavi?",
    answer: dedent`
      Kimavi is a platform focused on driving Customer Success through its tools that help create engaging content. It offers features such as:
      
      1. **Video Tutorials**: Users can create video tutorials easily using the Video Script Creator.
      2. **How-To Guides**: The How-To Guide Creator, powered by an AI Browser Extension, allows for quick creation of guides and documents.
      3. **Custom ChatGPT**: Users can train a ChatGPT on their product to engage prospects, collect leads, and assist customers with personalized support.
      
      By utilizing these tools, users can enhance their customer engagement and streamline content creation. 
      
      If you have more specific questions or need assistance with any feature, feel free to ask!
      `,
  },
  {
    question: "How to create Video Tutorials?",
    answer: "https://www.youtube.com/watch?v=2GIumvSUO_Q",
  },
  {
    question: "How to create How-To Tutorials?",
    answer: "https://www.youtube.com/watch?v=HJ27CY8T_nY",
  },

  {
    question:
      "Can you give me an example of a video that customer has created?",
    answer: "https://www.youtube.com/watch?v=CbdpSr1VW3w",
  },

  {
    question: "What is blog to video? Can you give me an exmaple?",
    answer: "https://www.youtube.com/watch?v=sjFTTq3g5vU",
  },

  {
    question: "Can you create podcasts? Give me an example?",
    answer: "https://www.youtube.com/watch?v=3iptXOvnN4g",
  },

  {
    question: "Give me an example of a How-To video created by the extension?",
    answer: "https://www.youtube.com/shorts/7ijhV7yfkJQ",
  },

  {
    question: "Who are your competitors?",
    answer:
      "Try us today and experience the difference! Of course, we have fantastic competitors, and we continuously learn from each other. But jokes aside, give us a try and see what sets us apart!",
  },
  {
    question: "How do I create a chatbot for my products?",
    answer: dedent`
  To create a chatbot for your product using Kimavi, you can follow these general steps:
  
  1. **Sign Up for a Subscription**: Choose a plan that includes the Custom ChatGPT feature. The Advanced AI Creator tier offers full access to this functionality.
  
  2. **Train Your ChatGPT**:
     - Go to the Custom ChatGPT section on the Kimavi platform.
     - Input relevant information about your product, including features, FAQs, and any other useful data that can help the chatbot engage prospects and assist customers.
  
  3. **Integrate with Your Platform**:
     - Use the provided embed code to add the chatbot to your website or mobile app.
     - Connect the chatbot to messaging platforms like WhatsApp and Slack for broader engagement.
  
  4. **Customize Appearance**: Personalize the chatbot’s appearance to match your brand identity, including colors, fonts, and logos.
  
  5. **Test Your Chatbot**: Interact with the chatbot to ensure it provides accurate responses and engages users effectively.
  
  6. **Monitor and Improve**: Use the analytics tools available to track interactions, gather feedback, and continuously improve the chatbot's performance.
  
  If you need detailed guidance on any specific step or feature, feel free to ask!
  `,
  },
  {
    question: "What subscription plans does Kimavi offer?",
    answer: dedent`
  Kimavi offers three subscription tiers to cater to different user needs:
  
  1. **Free Subscription**
     - **Preview**: Get started without a credit card.
     - **Create How-To Guides with Limits**: Create your first How-To Guide via the extension.
     - **Create Video Tutorials with Limits**: Create your first Video Tutorial with limited features.
     - **Learn**: Explore our Videos and Guides.
     - **Community Support**: Access to community forums.
     - **Analytics**: Not available.
     - **Support**: Via Video Tutorials.
  
  2. **Basic AI Creator - $10 a month**
     - **Unlock Basic AI Features** (Guides + Videos).
     - **Create How-To Guides**: AI-assisted How-To Guides with standard editing tools.
     - **Create Video Tutorials**: AI-assisted Video Creation with standard editing tools.
     - **Basic Audio**: Access AI-generated voices.
     - **Learn**: Full access to courses.
     - **Basic Analytics**: Track views and engagement on your content.
     - **Support**: Via Email.
     - **Create Custom ChatGPT**: Upgrade to Advanced AI Creator for this feature.
  
  3. **Advanced AI Creator - $20 a month**
     - **Full Access to Premium AI Features** (Guides + Videos + ChatGPT).
     - **Create How-To Guides**: Full AI-assisted How-To Guides with advanced editing tools.
     - **Create Video Tutorials**: Full AI-assisted Video Creation with advanced editing tools.
     - **Create Custom ChatGPT**: Personalized ChatGPT for your product—deploy on your website.
     - **Premium Audio**: Access AI-generated premium voices.
     - **Advanced Analytics**: In-depth AI analytics and reporting.
     - **Customize**: Advanced template customization.
     - **On-Prem Hosting**: Contact us for On-Prem hosted solutions.
     - **SSO Integration**: Support for Single Sign-On.
     - **Priority Support**: Via Email, Phone, and Zoom.
  `,
  },
  {
    question: "How do I use the Image Editor in Kimavi?",
    answer: dedent`
  To use the Image Editor in Kimavi, follow these steps:
  
  1. **Access the Image Editor**: Within the Video Script Creator or How-To Guide Creator, select the option to upload or edit images.
  
  2. **Upload an Image**: Click on the "Upload" button to add an image from your device.
  
  3. **Edit the Image**:
     - **Draw**: Use the drawing tools to annotate or highlight parts of the image.
     - **Blur**: Apply blurring to sensitive information or areas you wish to obscure.
     - **Crop**: Trim the image to focus on specific content.
     - **Position**: Adjust the placement of the image within your content.
  
  4. **Save Changes**: Once you're satisfied with the edits, save the image to include it in your tutorial or guide.
  
  5. **Preview**: Review how the edited image appears in your content to ensure it meets your requirements.
  
  If you need further assistance with image editing features, feel free to ask!
  `,
  },
  {
    question: "How can I publish videos in multiple formats?",
    answer: dedent`
  Publishing videos in multiple formats with Kimavi is straightforward:
  
  1. **Create Your Video**: Use the Video Script Creator to develop your tutorial content.
  
  2. **Customize Formats**:
     - Before publishing, select the formats you wish to generate: **Landscape**, **Portrait**, and **Shorts**.
     - Adjust any format-specific settings if needed.
  
  3. **Review Content and Audio**:
     - Ensure that your script and audio are finalized.
     - Preview the video in each format to check for any layout or content adjustments.
  
  4. **Publish**:
     - Click the "Publish" button.
     - Kimavi will generate the videos in the selected formats.
  
  5. **Access Your Videos**:
     - Once processing is complete, you can download or share the videos directly from the platform.
     - Utilize the provided links or embed codes to share on various platforms.
  
  By publishing in multiple formats, you can reach a wider audience across different platforms and devices.
  `,
  },
  {
    question: "How do I customize the appearance of my video tutorials?",
    answer: dedent`
  Customizing the appearance of your video tutorials in Kimavi enhances brand identity:
  
  1. **Access Customization Settings**:
     - In the Video Script Creator, navigate to the **Customization** or **Settings** section.
  
  2. **Backgrounds**:
     - Choose from predefined backgrounds or upload your own images.
     - Adjust background colors and patterns to match your branding.
  
  3. **Fonts**:
     - Select from various font styles for your text.
     - Ensure readability and consistency with your brand guidelines.
  
  4. **Footers**:
     - Add footer text or images, such as logos or disclaimers.
     - Customize the footer's appearance and position.
  
  5. **Themes and Templates**:
     - Apply themes that align with your content's tone.
     - Use templates to maintain consistency across multiple videos.
  
  6. **Preview Changes**:
     - Review how the customizations affect your video.
     - Make adjustments as necessary before publishing.
  
  Personalizing your tutorials helps in creating a professional and cohesive experience for your audience.
  `,
  },
  {
    question: "How do I connect my chatbot to WhatsApp and Slack?",
    answer: dedent`
  Connecting your Kimavi Custom ChatGPT to messaging platforms extends its reach:
  
  1. **Access Integration Settings**:
     - Go to the **Integrations** section within the Custom ChatGPT area.
  
  2. **Connect to WhatsApp**:
     - Follow the prompts to link your WhatsApp Business account.
     - Configure messaging settings and permissions.
     - Verify the connection by sending a test message.
  
  3. **Connect to Slack**:
     - Authorize Kimavi to access your Slack workspace.
     - Select the channels where the chatbot should be active.
     - Customize bot responses and commands if necessary.
  
  4. **Test the Integrations**:
     - Interact with the chatbot on both platforms to ensure functionality.
     - Check for any issues with message formatting or delays.
  
  5. **Monitor Interactions**:
     - Use Kimavi's analytics to track engagement on these platforms.
     - Adjust settings based on user feedback and interaction data.
  
  If you encounter any difficulties during the integration process, don't hesitate to reach out for support.
  `,
  },
  {
    question: "Can I use my own voice in the video tutorials?",
    answer: dedent`
  Yes, you can use your own voice in Kimavi's video tutorials:
  
  1. **Record Your Voice**:
     - Within the Video Script Creator, navigate to the **Audio** section.
     - Select the option to **Record Voice**.
     - Use your device's microphone to record narration for each slide or the entire video.
  
  2. **Upload Pre-Recorded Audio**:
     - If you have existing audio files, you can upload them.
     - Ensure the audio aligns with your video content.
  
  3. **Edit Audio**:
     - Trim or adjust the audio clips as needed.
     - Synchronize the audio with the corresponding slides or visuals.
  
  4. **Preview**:
     - Play back the video to ensure audio quality and synchronization.
     - Make any necessary adjustments before publishing.
  
  Using your own voice adds a personal touch and can enhance the connection with your audience.
  `,
  },
  {
    question: "What advanced features are available to enhance my content?",
    answer: dedent`
  Kimavi offers several advanced features to enrich your tutorials and guides:
  
  1. **LaTeX Formulas**:
     - Include mathematical equations and formulas using LaTeX syntax.
     - Ideal for educational content requiring precise mathematical representations.
  
  2. **Emojis and Icons**:
     - Add visual elements like emojis to make content more engaging.
     - Use icons to highlight important information.
  
  3. **Code Snippets**:
     - Insert formatted code blocks for programming tutorials.
     - Supports syntax highlighting for various programming languages.
  
  4. **Multiple Languages**:
     - Create content in different languages to reach a global audience.
     - Utilize language-specific fonts and formatting.
  
  5. **Interactive Elements**:
     - Include quizzes or interactive questions within your content.
     - Encourage audience participation and retention.
  
  6. **Advanced Animations**:
     - Apply custom animations to text and images.
     - Control timing and transitions for a dynamic presentation.
  
  Exploring these features can significantly enhance the quality and effectiveness of your content.
  `,
  },
  {
    question: "If Kimavi were a superhero, what powers would it have?",
    answer: dedent`
     If Kimavi were a superhero, its powers would include:

     - **Content Creation Beam**: Instantly generate engaging videos and guides with a single beam!
     - **AI Sidekick**: A trusty AI companion that never runs out of ideas or energy.
     - **Analytics X-Ray Vision**: See through data to understand exactly what your customers need.
     - **Customization Shield**: Protect and personalize your content with unbeatable style.
     
     Together, these powers ensure your content always saves the day!
     `,
  },
  {
    question: "What’s Kimavi’s favorite type of coffee?",
    answer:
      "A latte with a shot of AI! It keeps our content creation energized and smooth ☕🤖",
  },
  {
    question: "Can Kimavi dance? If yes, what’s its signature move?",
    answer: dedent`
     Absolutely! Kimavi's signature dance move is the **"Content Shuffle"**:
     
     1. **Slide Step**: Moving seamlessly between video tutorials and how-to guides.
     2. **AI Twist**: Spinning creative ideas with a touch of AI magic.
     3. **Engagement Spin**: Twirling to keep the audience captivated and entertained.
     
     Whether it's a quick tutorial or a detailed guide, Kimavi dances its way to delivering top-notch content!
     `,
  },
  {
    question: "If Kimavi could have a pet, what would it be?",
    answer:
      "A digital owl named 'ScriptWise' 🦉—wise in creating scripts and always watching over your content!",
  },
  {
    question: "What song does Kimavi sing when no one is watching?",
    answer:
      "🎶 'Don't Stop Believin' by Journey' – because with Kimavi, your customers will always reach new heights! 🎶",
  },
];

export default knownQA;
