// config.js

let environment;

if (window.location.hostname.includes("kimavi")) {
  environment = "production";
} else {
  environment = "development";
  // environment = "production";
  // environment = "development";
}

export const NODE_ENV = environment;
