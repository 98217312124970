// ChatBot.js
import ReactPlayer from "react-player";
import knownQA from "./knownQA.js";
import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { NODE_ENV } from "./constants/config";
import { FaUser, FaTimes, FaCommentDots, FaRegCopy } from "react-icons/fa";
import defaultBotLogo from "./logo.png"; // Default logo
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import "katex/dist/katex.min.css"; // Import KaTeX CSS for math rendering

// Fisher-Yates Shuffle Algorithm
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

function ChatBot({
  modal = false,
  isOpen: propIsOpen = false,
  onClose,
  iframeMode = false,
  botId: propBotId,
  customization: propCustomization = {},
}) {
  const [askedQuestions, setAskedQuestions] = useState([]);
  // Retrieve botId and customization from URL parameters if not provided via props
  const urlParams = new URLSearchParams(window.location.search);
  const botId = propBotId || urlParams.get("botId") || "kimavi-101"; // Default botId

  // Default colors
  const defaultHeaderBackgroundColor = "#000000";
  const defaultHeaderFontColor = "#00ffdd";
  const defaultUserIconColor = "#2DD4BF"; // teal color
  const defaultIconBackgroundColor = "#000000"; // black
  const defaultIconFrontColor = "#00ffdd"; // teal color
  const defaultUserBackgroundColor = "#000000"; // black
  const defaultUserFontColor = "#00ffdd"; // teal color
  const defaultBackgroundColor = "#ffffff"; // white
  const defaultForegroundColor = "#000000"; // black
  const defaultButtonBackgroundColor = "#000000"; // black
  const defaultButtonFontColor = "#00ffdd"; // teal color
  const defaultBotMessageBackgroundColor = "#e2e8f0"; // gray-300
  const defaultBotMessageTextColor = "#2d3748"; // gray-800

  const [thinkingDots, setThinkingDots] = useState("");
  // Initialize customization settings
  const [customization, setCustomization] = useState({
    logo: urlParams.get("logo") || propCustomization.logo || defaultBotLogo,
    backgroundColor:
      urlParams.get("backgroundColor") ||
      propCustomization.backgroundColor ||
      defaultBackgroundColor,
    foregroundColor:
      urlParams.get("foregroundColor") ||
      propCustomization.foregroundColor ||
      defaultForegroundColor,
    fontFamily:
      urlParams.get("fontFamily") ||
      propCustomization.fontFamily ||
      "Arial, sans-serif",
    headerTitle:
      urlParams.get("headerTitle") ||
      propCustomization.headerTitle ||
      "Kimavi Chatbot - Built on ChatGPT",
  });

  const [isOpen, setIsOpen] = useState(iframeMode ? true : propIsOpen);

  // Initialize messages with default greeting
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: `👋 **Hello!** I'm here to help you out! 😊  
          
I'm an AI chatbot trained on Kimavi products:  **Chat**, **Guides**, and **Videos**.   
    
    
💬 I can respond with **Rich Text** ✍️, **images** 🖼️, **audio** 🎧, and **video** 📹!  
    
    
Just ask me _anything_ about Kimavi products! I even know a little bit about Kimavi's competitors!! 😊
`,
      isInitial: true, // Mark this message as the initial one
    },
  ]);

  // Initialize suggestions with default questions
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Shuffle questions and pick the first two
    const shuffledQuestions = shuffleArray(knownQA);
    setSuggestions(shuffledQuestions.slice(0, 2).map((qa) => qa.question));
  }, []);

  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [botResponse, setBotResponse] = useState(""); // For typing simulation
  const [typingIntervalId, setTypingIntervalId] = useState(null);

  const [showPopup, setShowPopup] = useState(true); // State to control the pop-up visibility

  const CHAR_LIMIT = 120;

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= CHAR_LIMIT) {
      setInput(value);
    }
  };

  useEffect(() => {
    let dotsInterval;
    if (isTyping && !botResponse) {
      dotsInterval = setInterval(() => {
        setThinkingDots((prevDots) => {
          if (prevDots.length >= 5) {
            return "";
          } else {
            return prevDots + ".";
          }
        });
      }, 500); // Adjust the interval as needed
    } else {
      setThinkingDots("");
    }

    return () => {
      if (dotsInterval) clearInterval(dotsInterval);
    };
  }, [isTyping, botResponse]);

  const messagesEndRef = useRef(null);

  const apiBaseUrl =
    NODE_ENV === "development1"
      ? "http://localhost:5001/json-storage-bed47/us-central1/api"
      : "https://us-central1-json-storage-bed47.cloudfunctions.net/api";

  // Fetch initial data (e.g., configurations from the server)
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/chatbot-config`, {
          params: { botId },
        });
        const config = response.data;

        // Merge initial messages
        if (config.initialMessages) {
          setMessages(config.initialMessages);
        }

        // Merge suggestions
        if (config.suggestions) {
          setSuggestions(config.suggestions);
        }

        // Merge customization settings
        setCustomization((prevCustomization) => ({
          ...prevCustomization,
          ...config.customization,
        }));
      } catch (error) {
        console.error("Error fetching chatbot configuration:", error);
        // Use default messages and suggestions already set in state
      }
    };

    // fetchInitialData();
  }, [botId]);

  // Previous message count to detect new bot messages
  const previousMessageCount = useRef(messages.length);

  useEffect(() => {
    // Only scroll when the bot finishes typing a new message
    if (!isTyping && messages.length > previousMessageCount.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      previousMessageCount.current = messages.length;
    }
  }, [isTyping, messages]);

  // Clean up typing interval on unmount
  useEffect(() => {
    return () => {
      if (typingIntervalId) {
        clearInterval(typingIntervalId);
      }
    };
  }, [typingIntervalId]);

  // Auto-close the pop-up after 15 seconds
  useEffect(() => {
    let popupTimer;
    if (showPopup) {
      popupTimer = setTimeout(() => {
        setShowPopup(false);
      }, 15000); // 15000 milliseconds = 15 seconds
    }

    return () => {
      if (popupTimer) clearTimeout(popupTimer);
    };
  }, [showPopup]);

  const sendMessage = async (messageText = input) => {
    if (messageText.trim() === "") return;

    const userMessage = { sender: "user", text: messageText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsTyping(true);

    try {
      const { text: responseText, question: answeredQuestion } =
        await getBotResponse(messageText);

      // Update asked questions
      if (answeredQuestion) {
        setAskedQuestions((prevAskedQuestions) => [
          ...prevAskedQuestions,
          answeredQuestion,
        ]);
      }

      // Immediately show bot response without typing effect
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: responseText },
      ]);
      setIsTyping(false);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Sorry, something went wrong." },
      ]);
      setIsTyping(false);
    }
  };

  const remainingSuggestions = useMemo(() => {
    return knownQA
      .filter((qa) => !askedQuestions.includes(qa.question))
      .map((qa) => qa.question);
  }, [askedQuestions]);

  const getBotResponse = async (userInput) => {
    const matchingQA = knownQA.find(
      (qa) => qa.question.toLowerCase() === userInput.toLowerCase()
    );

    if (matchingQA) {
      setIsTyping(true); // Start thinking indicator

      // Simulate thinking for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setIsTyping(false); // Stop thinking indicator
      return { text: matchingQA.answer, question: matchingQA.question };
    } else {
      // Existing code for API call
      try {
        const payload = {
          botId,
          message: userInput,
          messages,
        };

        const response = await axios.post(`${apiBaseUrl}/chat`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        return { text: response.data.reply };
      } catch (error) {
        console.error("Error fetching bot response:", error);
        toast.error("Failed to get response from the chatbot.");
        return { text: "Sorry, I am having trouble responding right now." };
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") sendMessage();
  };

  const renderMessageContent = (msg, idx) => {
    if (msg.sender === "bot") {
      const isYouTubeLink =
        /^https?:\/\/(www\.)?youtube\.com\/(watch\?v=|shorts\/)/.test(msg.text);

      const displayedSuggestions =
        idx === 0
          ? suggestions // Use initial suggestions for the first bot message
          : remainingSuggestions.slice(0, 2); // Use up to two remaining suggestions for subsequent messages

      if (isYouTubeLink) {
        return (
          <div>
            <p>Please watch this video!</p>
            <ReactPlayer url={msg.text} controls width="100%" height="auto" />
            {displayedSuggestions.length > 0 && (
              <div className="mt-5 mb-2 flex flex-wrap gap-2 ">
                {displayedSuggestions.map((suggestion, i) => (
                  <button
                    key={i}
                    className="text-left px-2 py-1 bg-gray-400 text-gray-100 rounded-md hover:bg-gray-600"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      } else {
        // Determine the suggestions to display

        return (
          <div>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw, rehypeKatex]}
              remarkPlugins={[remarkGfm, remarkMath]}
              components={{
                // Paragraphs
                p: ({ node, children, ...props }) => (
                  <p className="mb-2" {...props}>
                    {children}
                  </p>
                ),
                // Headings
                h1: ({ node, children, ...props }) => (
                  <h1 className="text-2xl font-bold my-2" {...props}>
                    {children}
                  </h1>
                ),
                h2: ({ node, children, ...props }) => (
                  <h2 className="text-xl font-semibold my-2" {...props}>
                    {children}
                  </h2>
                ),
                h3: ({ node, children, ...props }) => (
                  <h3 className="text-lg font-semibold my-2" {...props}>
                    {children}
                  </h3>
                ),
                h4: ({ node, children, ...props }) => (
                  <h4 className="text-base font-semibold my-2" {...props}>
                    {children}
                  </h4>
                ),
                // Unordered List
                ul: ({ node, children, depth, ...props }) => (
                  <ul className="list-disc ml-5" {...props}>
                    {children}
                  </ul>
                ),
                // Ordered List
                ol: ({ node, children, depth, ...props }) => (
                  <ol className="list-decimal ml-5" {...props}>
                    {children}
                  </ol>
                ),
                // List Item
                li: ({ node, children, ...props }) => (
                  <li className="my-1" {...props}>
                    {children}
                  </li>
                ),
                // Code Blocks and Inline Code
                code: ({ node, inline, className, children, ...props }) => {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      style={materialDark}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                  ) : (
                    <code className="bg-gray-100 p-1 rounded" {...props}>
                      {children}
                    </code>
                  );
                },
                // Strong (Bold) Text
                strong: ({ node, children, ...props }) => (
                  <strong {...props}>{children}</strong>
                ),
                // Emphasized (Italic) Text
                em: ({ node, children, ...props }) => (
                  <em {...props}>{children}</em>
                ),
                // Blockquote
                blockquote: ({ node, children, ...props }) => (
                  <blockquote
                    className="border-l-4 border-gray-300 pl-4 italic my-2"
                    {...props}
                  >
                    {children}
                  </blockquote>
                ),
                // Horizontal Rule
                hr: ({ node, ...props }) => <hr className="my-4" {...props} />,
                // Images
                img: ({ node, ...props }) => (
                  <img className="my-2 max-w-full" {...props} alt="" />
                ),
                // Links
                a: ({ node, children, ...props }) => (
                  <a
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {msg.text}
            </ReactMarkdown>
            {/* Display the suggestions */}
            {displayedSuggestions.length > 0 && (
              <div className="mt-5 mb-2 flex flex-wrap gap-2 ">
                {displayedSuggestions.map((suggestion, i) => (
                  <button
                    key={i}
                    className="text-left px-2 py-1 bg-gray-400 text-gray-100 rounded-md hover:bg-gray-600"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      }
    } else {
      return <span>{msg.text}</span>;
    }
  };

  const handleSuggestionClick = (suggestion) => {
    sendMessage(suggestion);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied...");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        toast.error("Failed to copy text.");
      });
  };

  // Apply customization settings
  const { logo, backgroundColor, foregroundColor, fontFamily, headerTitle } =
    customization;

  // Shared content for messages and input field
  const chatContent = (
    <>
      {/* Messages */}
      <div
        className="flex-1 overflow-y-auto p-4"
        style={{ fontFamily, backgroundColor, color: foregroundColor }}
      >
        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={`flex my-2 ${
              msg.sender === "user" ? "justify-end" : "justify-start"
            }`}
          >
            {msg.sender === "bot" ? (
              <div className="flex items-start space-x-2">
                <img
                  src={logo}
                  alt="Bot Logo"
                  className="w-10 h-10 rounded-full"
                />

                <div>
                  <div
                    className="p-2 rounded-lg max-w-xs"
                    style={{
                      backgroundColor:
                        customization.botMessageBackgroundColor ||
                        defaultBotMessageBackgroundColor,
                      color:
                        customization.botMessageTextColor ||
                        defaultBotMessageTextColor,
                    }}
                  >
                    {renderMessageContent(msg, idx)}
                  </div>
                  {/* Copy Button */}
                  {idx > 0 &&
                    messages[idx - 1].sender === "user" &&
                    !msg.isInitial && (
                      <div className="flex space-x-2 mt-1">
                        <button onClick={() => handleCopy(msg.text)}>
                          <FaRegCopy className="text-gray-600 hover:text-blue-500" />
                        </button>
                      </div>
                    )}
                </div>
              </div>
            ) : (
              <div className="flex items-end space-x-2">
                <div
                  className="p-2 rounded-lg max-w-xs"
                  style={{
                    backgroundColor:
                      customization.userMessageBackgroundColor ||
                      defaultUserBackgroundColor,
                    color:
                      customization.userMessageTextColor ||
                      defaultUserFontColor,
                  }}
                >
                  <div ref={messagesEndRef} />
                  {renderMessageContent(msg, idx)}
                </div>
                <FaUser
                  className="text-2xl"
                  style={{
                    color: customization.userIconColor || defaultUserIconColor,
                  }}
                />
              </div>
            )}
          </div>
        ))}

        {/* Bot Typing Simulation */}
        {isTyping && botResponse && (
          <div className="flex items-start space-x-2 my-2">
            <img src={logo} alt="Bot Logo" className="w-10 h-10 rounded-full" />
            <div
              className="p-2 rounded-lg max-w-xs"
              style={{
                backgroundColor:
                  customization.botMessageBackgroundColor ||
                  defaultBotMessageBackgroundColor,
                color:
                  customization.botMessageTextColor ||
                  defaultBotMessageTextColor,
              }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]} components={{}}>
                {botResponse}
              </ReactMarkdown>
            </div>
          </div>
        )}

        {/* Thinking Indicator */}
        {isTyping && !botResponse && (
          <div className="flex items-start space-x-2 my-2">
            <img src={logo} alt="Bot Logo" className="w-10 h-10 rounded-full" />
            <div
              className="p-2 rounded-lg max-w-xs"
              style={{
                backgroundColor:
                  customization.botMessageBackgroundColor ||
                  defaultBotMessageBackgroundColor,
                color:
                  customization.botMessageTextColor ||
                  defaultBotMessageTextColor,
              }}
            >
              Thinking{thinkingDots}
            </div>
          </div>
        )}

        {/* Scroll to bottom */}
      </div>

      {/* Input Field */}
      <div className="p-4 flex items-center space-x-2">
        <input
          className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none"
          type="text"
          placeholder="Type your message..."
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{ fontFamily }}
        />
        <button
          className="px-4 py-2 rounded-md"
          style={{
            backgroundColor: defaultButtonBackgroundColor,
            color: defaultButtonFontColor,
            fontFamily,
          }}
          onClick={() => sendMessage()}
          disabled={input.length === 0 || isTyping}
        >
          Send
        </button>
      </div>

      {/* Character Limit Indicator */}
      {input && input.length > 0 && (
        <div className="px-4 py-2 text-sm text-gray-500 text-center">
          {input.length}/{CHAR_LIMIT}
        </div>
      )}

      {/* Privacy Policy */}
      <div className="text-xs text-center p-2">
        By chatting, you agree to our{" "}
        <a
          href="https://www.kimavi.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          privacy policy
        </a>
        .
      </div>
    </>
  );

  if (iframeMode) {
    return (
      <div
        className="w-full h-full flex flex-col"
        style={{ backgroundColor, color: foregroundColor, fontFamily }}
      >
        {/* Header */}
        <div
          className="p-2 flex justify-between items-center rounded-xl"
          style={{
            backgroundColor: defaultHeaderBackgroundColor,
            color: defaultHeaderFontColor,
          }}
        >
          <h1 className="text-lg font-bold">{headerTitle}</h1>
        </div>
        {chatContent}
      </div>
    );
  } else if (modal) {
    // Modal mode
    if (!isOpen) {
      return null;
    }

    return (
      // Modal Overlay
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        {/* Modal Content */}
        <div
          className="bg-white rounded-lg overflow-hidden w-full max-w-md max-h-full flex flex-col"
          style={{ fontFamily }}
        >
          {/* Header */}
          <div
            className="p-2 flex justify-between items-center"
            style={{
              backgroundColor: defaultHeaderBackgroundColor,
              color: defaultHeaderFontColor,
            }}
          >
            <h1 className="text-lg font-bold">{headerTitle}</h1>
            <button onClick={onClose}>
              <FaTimes className="text-xl" />
            </button>
          </div>

          {chatContent}
        </div>
      </div>
    );
  } else {
    // Regular rendering without modal
    return (
      <>
        {/* Pop-up and Chat Bubble Icon */}
        {!isOpen && (
          <>
            {showPopup && (
              <div className="fixed bottom-20 right-4 z-50 ">
                {/* Pop-up Message */}
                <div
                  className="bg-teal-400 shadow-lg rounded-lg p-4 mb-2 relative"
                  style={{ maxWidth: "300px" }}
                >
                  {/* Close Button */}
                  <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={() => setShowPopup(false)}
                  >
                    <FaTimes />
                  </button>
                  <div
                    className="flex items-start cursor-pointer bg-gray-200 m-2 p-2"
                    onClick={() => {
                      setIsOpen(true);
                      setShowPopup(false);
                    }}
                  >
                    <img
                      src={logo}
                      alt="Bot Logo"
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    <div>
                      <p className="text-sm mb-1">
                        👋 Hello! I'm Kimavi ChatBot, I am here to help you out!
                        😊
                      </p>
                      <p></p>
                      <hr></hr>
                      <p></p>
                      <p className="text-sm">
                        ✍️ You can design a chatbot like me...Try me out! 😮
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="fixed bottom-4 right-4 z-50">
              <button
                className="p-3 rounded-full"
                onClick={() => {
                  setIsOpen(true);
                  setShowPopup(false);
                }}
                style={{
                  backgroundColor: defaultIconBackgroundColor,
                  color: defaultIconFrontColor,
                }}
              >
                <FaCommentDots className="text-2xl" />
              </button>
            </div>
          </>
        )}

        {/* Chat Window */}
        {isOpen && (
          <div
            className="fixed bottom-0 right-0 w-full max-w-md h-3/4 shadow-lg flex flex-col z-50"
            style={{ backgroundColor, color: foregroundColor, fontFamily }}
          >
            {/* Header */}
            <div
              className="p-2 flex justify-between items-center"
              style={{
                backgroundColor: defaultHeaderBackgroundColor,
                color: defaultHeaderFontColor,
              }}
            >
              <h1 className="text-lg font-bold">{headerTitle}</h1>
              <button onClick={() => setIsOpen(false)}>
                <FaTimes className="text-xl" />
              </button>
            </div>

            {chatContent}
          </div>
        )}
      </>
    );
  }
}

export default ChatBot;
