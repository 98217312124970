import React from "react";
import { FiVideo, FiImage, FiFileText, FiDownload } from "react-icons/fi";

const DownloadComponent = ({ guideData }) => {
  const guideDataCopy = JSON.parse(JSON.stringify(guideData));

  let { media, mediaPortrait } = guideDataCopy;

  // Include portraitVideo in mediaPortrait directly
  if (media.portraitVideo) {
    mediaPortrait = { ...mediaPortrait, portraitVideo: media.portraitVideo };
    delete media.portraitVideo;
  }

  // Filter for unique values based on URLs
  const filterForUniqueValues = (mediaObj) => {
    const seenUrls = new Set();
    return Object.entries(mediaObj).filter(([_, value]) => {
      if (!seenUrls.has(value)) {
        seenUrls.add(value);
        return true;
      }
      return false;
    });
  };

  // Apply the uniqueness filter
  const uniqueMediaEntries = filterForUniqueValues(media);
  const uniqueMediaPortraitEntries = filterForUniqueValues(mediaPortrait);

  const generateIcon = (key) => {
    if (key.includes("video")) {
      return <FiVideo className="text-red-500 mr-2 text-3xl" />;
    } else if (key.includes("gif")) {
      return <FiImage className="text-green-500 mr-2 text-3xl" />;
    } else if (
      key.includes("slide") ||
      key.includes("title") ||
      key.includes("end")
    ) {
      return <FiFileText className="text-blue-500 mr-2 text-3xl" />;
    }
    if (key.includes("portraitVideo")) {
      return <FiVideo className="text-red-500 mr-2 text-3xl" />;
    } else {
      return <FiDownload className="text-gray-500 mr-2 text-3xl" />;
    }
  };

  const generateTitle = (key, url) => {
    let title = "Download File";
    const dimensions = url.match(/(\d+)x(\d+)/);
    const resolutionMatch = key.match(/(\d+)/);

    if (key === "portraitVideo") {
      title = "Download Video File";
    } else if (key.includes("video")) {
      title = "Download Video";
    } else if (key.includes("gif")) {
      const resolution = resolutionMatch ? `${resolutionMatch[0]} width` : "";
      title = `Download Animated GIF${resolution ? ` (${resolution})` : ""}`;
    } else if (key.includes("slide")) {
      title = "Download Slide";
    } else if (key.includes("title")) {
      title = "Title Thumbnail (For YouTube)";
    } else if (key.includes("end")) {
      title = "End Thumbnail (For YouTube)";
    }

    return title;
  };

  // Function to sort keys to group similar types
  const sortKeys = (a, b) => {
    const priority = { gif: 1, video: 2, slide: 3, title: 4, end: 5 };
    const aPriority = priority[a.match(/gif|video|slide|title|end/)?.[0]] || 6;
    const bPriority = priority[b.match(/gif|video|slide|title|end/)?.[0]] || 6;
    return aPriority - bPriority;
  };

  const renderAssets = (filteredEntries) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {filteredEntries
        .sort(([aKey], [bKey]) => sortKeys(aKey, bKey))
        .map(([key, value]) => {
          const title = generateTitle(key, value);
          const icon = generateIcon(key);
          return (
            <a
              key={key}
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-400 rounded-lg shadow-xl p-6 flex items-center"
            >
              {icon}
              <div>
                <h4 className="text-lg font-bold mb-2 text-[#00FFFF]">
                  {title}
                </h4>
                <p className="text-md text-white">Click to download/view</p>
              </div>
            </a>
          );
        })}
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-500 mb-4">
          Landscape Assets
        </h2>
        {renderAssets(uniqueMediaEntries)}
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-500 mb-4">
          Portrait Assets
        </h2>
        {renderAssets(uniqueMediaPortraitEntries)}
      </div>
    </div>
  );
};

export default DownloadComponent;
