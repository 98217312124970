import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaSalesforce,
  FaEnvelope,
  FaYoutube,
  FaGoogle,
  FaChrome,
  FaMicrosoft,
  FaBook,
  FaChalkboardTeacher,
  FaGraduationCap,
  FaApple,
  FaAmazon,
  FaSlack,
  FaHubspot,
  FaShopify,
  FaCode,
  FaFlask,
  FaCalculator,
  FaLaptopCode,
  FaAtom,
  FaComments,
  FaCommentDots,
  FaCommentAlt,
  FaRegCommentDots,
  FaTelegramPlane,
  FaWeixin,
  FaWhatsapp,
  FaTeamspeak,
} from "react-icons/fa";
import { MdChatBubble, MdChatBubbleOutline, MdChat } from "react-icons/md";
import ChatBot from "../chatbot";

import {
  FaVideo,
  FaFilm,
  FaPlay,
  FaCamera,
  FaPhotoVideo,
  FaMicrophone,
  FaEdit,
  FaRegFileVideo,
  FaPaintBrush,
} from "react-icons/fa";

import {
  MdArrowForwardIos,
  MdArrowBackIos,
  MdOutlineRefresh,
} from "react-icons/md";

function Footer() {
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const guidesPerPage = 3;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleGuides, setVisibleGuides] = useState([]);

  const logos = [
    FaSalesforce,
    FaApple,
    FaMicrosoft,
    FaGoogle,
    FaAmazon,
    FaSlack,
    FaHubspot,
    FaYoutube,
    FaShopify,
  ];

  const educationalLogos = [
    FaBook,
    FaChalkboardTeacher,
    FaGraduationCap,
    FaCode,
    FaFlask,
    FaCalculator,
    FaLaptopCode,
    FaAtom,
  ];

  const videoCreationLogos = [
    FaVideo,
    FaFilm,
    FaPlay,
    FaCamera,
    FaPhotoVideo,
    FaMicrophone,
    FaEdit,
    FaRegFileVideo,
    FaPaintBrush,
  ];

  const chatIcons = [
    FaComments,
    FaCommentDots,
    FaCommentAlt,
    FaRegCommentDots,
    FaTelegramPlane,
    FaWeixin,
    FaWhatsapp,
    FaSlack,
    FaTeamspeak,
    MdChatBubble,
    MdChatBubbleOutline,
    MdChat,
  ];

  const features = [
    {
      title: "Customizable Chatbot",
      description:
        "Tailor ChatGPT to align perfectly with your product and brand.",
      color: "bg-red-200",
      hoverColor: "hover:bg-red-300",
      textColor: "text-red-800",
    },
    {
      title: "Seamless Integration",
      description:
        "Easily integrate into your website or Moible App, WhatsApp, Slack or Zapier with minimal effort.",
      color: "bg-green-200",
      hoverColor: "hover:bg-green-300",
      textColor: "text-green-800",
    },
    {
      title: "Advanced Analytics",
      description:
        "Gain insights into user interactions and improve engagement.",
      color: "bg-blue-200",
      hoverColor: "hover:bg-blue-300",
      textColor: "text-blue-800",
    },
  ];

  // Shuffle the array
  function shuffleArray(array) {
    return array
      .map((item) => ({ ...item, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey);
  }

  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @keyframes scrollLogos {
        0% { transform: translateX(0); }
        100% { transform: translateX(-50%); }
      }

      .logo-slider {
        overflow: hidden;
      }

      .logo-track {
        display: flex;
        width: 200%;
        animation: scrollLogos 20s linear infinite;
      }

      .logo-item {
        flex-shrink: 0;
        width: calc(100% / 8);
        box-sizing: border-box;
      }

      @keyframes fadeOut {
        0% { opacity: 1; }
        100% { opacity: 0; }
      }

      @keyframes slideInUp {
        0% { opacity: 0; transform: translateY(100%); }
        100% { opacity: 1; transform: translateY(0); }
      }

      .fade-out { animation: fadeOut 1s forwards; }
      .animate-slide-in-up { animation: slideInUp 1s ease-out forwards; }
    `;
    document.head.appendChild(styleTag);
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  const [videoMessageIndex, setVideoMessageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  const videoMessages = [
    "⚡ Custom ChatGPT - Drive Customer Success⚡",
    "⚡ In-App How-To Guides - Drive Customer Success⚡",
    "⚡ In-App Videos - Drive Customer Success⚡",
    "⚡ Custom ChatGPT - Drive Customer Onboarding, Help and Support ⚡",
    "⚡ In-App How-To Guides - Drive Customer Onboarding, Help and Support ⚡",
    "⚡ In-App Videos - Drive Customer Onboarding, Help and Support ⚡",
  ];

  useEffect(() => {
    const videoInterval = setInterval(() => {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setVideoMessageIndex(
          (prevIndex) => (prevIndex + 1) % videoMessages.length
        );
        setAnimationClass("animate-slide-in-up");
      }, 1000);
    }, 5000);

    return () => clearInterval(videoInterval);
  }, []);

  return (
    <footer className="bg-gray-100 py-8 flex-shrink-0">
      {/* Separator */}

      {/* Separator */}
      <div className="container mx-auto px-4 text-center my-8">
        <hr className="border-gray-300" />
      </div>

      {/* Customer ChatGPT Section */}
      <div className="container mx-auto px-4 text-center my-8">
        {/* Updated Pitch */}
        <h1 className="text-4xl font-extrabold text-center text-gray-500 mb-6 mt-6">
          Unlock the Future of Customer and Prospect Product Engagement
        </h1>
        <h1 className="text-3xl font-extrabold text-center text-gray-400 mb-6">
          Personalized Customer ChatGPT!
        </h1>
        <div className="flex flex-col items-center space-y-4">
          <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl">
            <p className="text-lg text-center">
              Elevate your customer and prospect experience to new heights with
              a custom ChatGPT trained exclusively on your product.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl">
            <p className="text-lg text-center">
              Seamlessly handle product-related questions, guide users through
              features, provide step-by-step how-to guides, and so much more.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl">
            <p className="text-lg text-center">
              Perfect for enhancing customer and partner support, boosting lead
              generation, and maximizing user engagement with interactive
              videos, guides and personalized responses.
            </p>
          </div>
        </div>

        {/* Rainbow Tailwind Cards */}
        <div className="text-center my-6">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">
            Key Features
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`${feature.color} ${feature.hoverColor} transition duration-200 p-6 rounded-lg shadow-lg text-center`}
            >
              <h3 className={`text-2xl font-semibold ${feature.textColor}`}>
                {feature.title}
              </h3>
              <p className="text-gray-700 mt-2">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* By invite only message */}
        <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl mt-6 mx-auto">
          <p className="text-lg text-center font-semibold">
            By invite only - email us to get an invitation!
          </p>
        </div>
      </div>

      {/* Chat Icons Slider */}
      <div className="logo-slider my-6">
        <div className="logo-track">
          {chatIcons.concat(chatIcons).map((IconComponent, index) => (
            <div key={index} className="logo-item">
              <IconComponent className="text-5xl text-gray-500 hover:text-gray-700 transition duration-200 mx-auto" />
            </div>
          ))}
        </div>
      </div>

      <div className="container mx-auto px-4 text-center my-8">
        <hr className="border-gray-300" />
      </div>

      {/* Call to Action */}
      <div className="container mx-auto px-4 text-center my-8">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
          Create Professional How To Tutorials in Minutes
        </h2>
        <h3 className="text-xl text-gray-500 mb-4">
          Seamlessly Create How To tutorials on Any Platform (Web, Mobile,
          Slides, or Browser Extensions)
        </h3>
      </div>

      {/* Call to Action Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
        <div className="bg-green-200 hover:bg-green-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-green-800">
            Workday, ServiceNow...
          </h3>
          <p className="text-gray-700 mt-2">
            See how we've crafted in-depth tutorials on Workday (HCM) and
            ServiceNow (ITSM). Learn from our examples and start creating your
            own!
          </p>
        </div>

        <div className="bg-blue-200 hover:bg-blue-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-blue-800">
            Microsoft, Google, Zoom...
          </h3>
          <p className="text-gray-700 mt-2">
            See how we break down complex software like Microsoft, Google, and
            Zoom. Get inspired and start making your own game-changing
            tutorials!
          </p>
        </div>

        <div className="bg-indigo-200 hover:bg-indigo-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-indigo-800">
            Salesforce, HubSpot, Zoho...
          </h3>
          <p className="text-gray-700 mt-2">
            Discover how we've mastered CRM platforms through expert tutorials.
            Use our videos as a blueprint to make your own!
          </p>
        </div>
      </div>

      {/* Logo Slider */}
      <div className="logo-slider my-6">
        <div className="logo-track">
          {logos.concat(logos).map((LogoComponent, index) => (
            <div key={index} className="logo-item">
              <LogoComponent className="text-5xl text-gray-500 hover:text-gray-700 transition duration-200 mx-auto" />
            </div>
          ))}
        </div>
      </div>

      {/* Separator */}
      <div className="container mx-auto px-4 text-center my-8">
        <hr className="border-gray-300" />
      </div>

      {/* Video Creation Tutorials Section */}
      <div className="container mx-auto px-4 text-center my-8">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
          Create Compelling Videos that Showcase Product Value
        </h2>
      </div>

      {/* Video Creation Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
        <div className="bg-purple-200 hover:bg-purple-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-purple-800">
            Create Engaging Use Case Videos
          </h3>
          <p className="text-gray-700 mt-2">
            Craft compelling videos that highlight real-world use cases of your
            product. Use our templates and tips to showcase product
            effectiveness and inspire your audience!
          </p>
        </div>

        <div className="bg-pink-200 hover:bg-pink-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-pink-800">
            Highlight Key Features with Short Videos
          </h3>
          <p className="text-gray-700 mt-2">
            Use engaging visuals and storytelling to communicate product
            benefits through one-minute shorts. Leverage social media to promote
            these videos, and share short clips and GIFs via chatbots.
          </p>
        </div>

        <div className="bg-teal-200 hover:bg-teal-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-teal-800">
            Capture Customer Success Stories
          </h3>
          <p className="text-gray-700 mt-2">
            Share customer testimonials and case studies through impactful
            videos. Show how your product transformed customer experiences and
            communicate its value effectively.
          </p>
        </div>
      </div>

      {/* Video Creation Logo Slider */}
      <div className="logo-slider my-6">
        <div className="logo-track">
          {videoCreationLogos
            .concat(videoCreationLogos)
            .map((LogoComponent, index) => (
              <div key={index} className="logo-item">
                <LogoComponent className="text-5xl text-gray-500 hover:text-gray-700 transition duration-200 mx-auto" />
              </div>
            ))}
        </div>
      </div>

      {/* Separator */}
      <div className="container mx-auto px-4 text-center my-8">
        <hr className="border-gray-300" />
      </div>

      {/* Success Story */}
      <div className="container mx-auto px-4 text-center mb-6">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-8">
          Your Path to Customer Success Success Starts Here!
        </h2>
      </div>

      {/* Success Story Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mx-auto max-w-screen-lg">
        <div className="bg-red-200 hover:bg-red-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-red-800">
            23,000+ Subscribers Trust Us
          </h3>
          <p className="text-gray-700 mt-2">
            Learn from our rapidly growing community of loyal subscribers. You
            can create videos, guides and chat bots just like we do! You have
            full access to the same tools we use! Unlock the same powerful
            tools, customize them to your needs, and start creating and driving
            Customer Success!
          </p>
        </div>

        <div className="bg-orange-200 hover:bg-orange-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-orange-800">
            10 Million+ Views
          </h3>
          <p className="text-gray-700 mt-2">
            Our Videos and Guides have garnered over 10 million views on
            YouTube. See our How To videos on products by visiting our YouTube
            channel. We publish on YouTube, Facebook, Instagram, Twitter,
            Pinterest & offer collection of videos as a paid course on uDemy and
            our own platform.
          </p>
        </div>

        <div className="bg-yellow-200 hover:bg-yellow-300 transition duration-200 p-6 rounded-lg shadow-lg text-center">
          <h3 className="text-2xl font-semibold text-yellow-800">
            Enterprise Experience
          </h3>
          <p className="text-gray-700 mt-2">
            Our team has enterprise-level experience deploying in-app and
            on-premises how-to guide software in organizations ranging from UBS,
            AbbVie, and Westpac Bank to the World Bank. We have deployed
            extensions on all employee machines at the biggest financial
            institutions passing all security and privacy checks.
          </p>
        </div>
      </div>

      {/* Educational Logo Slider */}
      <div className="logo-slider my-6">
        <div className="logo-track">
          {educationalLogos
            .concat(educationalLogos)
            .map((LogoComponent, index) => (
              <div key={index} className="logo-item">
                <LogoComponent className="text-5xl text-gray-500 hover:text-gray-700 transition duration-200 mx-auto" />
              </div>
            ))}
        </div>
      </div>

      {/* Footer Content */}
      <div className="container mx-auto px-4 text-center">
        <hr className="my-6 border-gray-400" />
        <div className="mb-4 flex space-x-6 justify-center">
          <a
            href="mailto:admin@kimavi.com"
            className="text-gray-600 hover:text-gray-800"
          >
            <FaEnvelope className="text-2xl" />
          </a>
          <a
            href="https://www.youtube.com/kimavi"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-gray-800"
          >
            <FaYoutube className="text-2xl" />
          </a>
          <a
            href="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-gray-800"
          >
            <FaChrome className="text-2xl" />
          </a>
        </div>

        <div className="text-gray-500 font-semibold mb-4">
          &copy; {currentYear} Kimavi. All rights reserved.
        </div>
        {/* Rotating Message */}
        <div className="text-center">
          <div
            className={`text-gray-800 font-semibold mb-6 text-3xl ${animationClass}`}
          >
            {videoMessages[videoMessageIndex]}
          </div>
        </div>
        <div className="text-center my-8">
          <Link
            to="/ai"
            className="inline-block bg-black text-teal-400 px-6 py-3 text-lg font-bold rounded-lg hover:bg-gray-800 transition duration-200"
          >
            Unlock the Power of Guides, Videos and Chat – Start Now!
          </Link>
        </div>

        <Link
          to="/privacy-security-cookie"
          className="text-gray-600 hover:text-gray-800"
        >
          <span className="text-sm font-semibold">
            Terms of Use: Privacy, Security, and Cookie Policy
          </span>
        </Link>

        <div className="mt-6 text-sm text-gray-500">
          For Help and Support, please email{" "}
          <a
            href="mailto:admin@kimavi.com"
            className="text-blue-600 hover:underline"
          >
            admin@kimavi.com
          </a>
        </div>
      </div>
      {/* Chat Icon */}

      {/* ChatBot Modal */}
      <>
        <ChatBot />
      </>
    </footer>
  );
}

export default Footer;
