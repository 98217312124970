import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCopy } from "react-icons/fi";

const GuideShare = ({ guideData }) => {
  const [htmlString, setHtmlString] = useState("");
  const [markdownString, setMarkdownString] = useState("");
  const [showLandscapePreview, setShowLandscapePreview] = useState(false);
  const [showPortraitPreview, setShowPortraitPreview] = useState(false);

  const { settings, media } = guideData;

  // Function to toggle the landscape preview
  const toggleLandscapePreview = () => {
    setShowLandscapePreview(!showLandscapePreview);
  };

  // Function to toggle the portrait preview
  const togglePortraitPreview = () => {
    setShowPortraitPreview(!showPortraitPreview);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!"); // You can replace this with a more sophisticated feedback mechanism
    });
  };

  useEffect(() => {
    // Generate HTML string
    let newHtmlString = `<div>\n<h1>${guideData.guideTitle}</h1>\n`;
    guideData.steps.forEach((step, index) => {
      const imageUrl = guideData.firebaseJSON
        ? guideData.firebaseJSON[index]
        : step.screenshotUrl;
      newHtmlString += `<div>\n<h2>Step ${index + 1}: ${step.stepTitle}</h2>\n${
        step.stepDescription
      }\n<img src="${imageUrl}" alt="Step ${index + 1}">\n</div>\n`;
    });
    newHtmlString += "</div>";
    setHtmlString(newHtmlString);

    // Generate Markdown string
    let newMarkdownString = `# ${guideData.guideTitle}\n\n`;
    guideData.steps.forEach((step, index) => {
      const imageUrl = guideData.firebaseJSON
        ? guideData.firebaseJSON[index]
        : step.screenshotUrl;
      newMarkdownString += `## Step ${index + 1}: ${step.stepTitle}\n${
        step.stepDescription
      }\n![Step ${index + 1}](${imageUrl})\n\n`;
    });
    setMarkdownString(newMarkdownString);
  }, [guideData]);

  //   / Function to determine base URL based on the current location
  const getBaseURL = () => {
    return window.location.origin.includes("localhost")
      ? "http://localhost:3000"
      : "https://www.kimavi.com";
  };

  // console.log(location.pathname);

  // console.log(getBaseURL());

  // Function to copy the embed iframe code to the clipboard for portrait orientation
  const copyPortraitEmbedCodeToClipboard = () => {
    const portraitEmbedCode = `<iframe src="${embedLink}" width="540" height="960" frameborder="0" allowfullscreen></iframe>`;
    navigator.clipboard.writeText(portraitEmbedCode).then(() => {
      toast.info("Portrait embed code copied to clipboard!");
    });
  };
  // Function to copy the embed iframe code to the clipboard

  const copyLandscapeEmbedCodeToClipboard = () => {
    const embedCode = `<iframe src="${embedLink}" width="960" height="540" frameborder="0" allowfullscreen></iframe>`;
    navigator.clipboard.writeText(embedCode).then(() => {
      toast.info("Embed code copied to clipboard!");
    });
  };

  // Function to copy the direct link to the clipboard for landscape orientation
  const copyLandscapeUrlToClipboard = () => {
    navigator.clipboard.writeText(embedLink).then(() => {
      toast.info("Landscape URL copied to clipboard!");
    });
  };

  // Function to copy the direct link to the clipboard for portrait orientation
  const copyPortraitUrlToClipboard = () => {
    // Assuming the portrait URL is the same as the landscape URL for direct access
    navigator.clipboard.writeText(embedLink).then(() => {
      toast.info("Portrait URL copied to clipboard!");
    });
  };

  // Generate the public link if the guide is public
  const publicLink = guideData.settings.public
    ? `${getBaseURL()}/video?id=${
        guideData.recordingSessionId
      }&source=server&uid=${guideData.uid}`
    : "";

  // Generate the embed link by appending &embed=true to the publicLink
  const embedLink = publicLink && `${publicLink}&embed=true`;

  useEffect(() => {
    // Initialize with existing settings if any
  }, [publicLink]);
  console.log(guideData);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{guideData.guideTitle}</h1>
      <Tabs>
        <TabList>
          <Tab>HTML</Tab>
          <Tab>Markdown</Tab>
          <Tab>Text</Tab>
          <Tab>Steps with Images</Tab>
          <Tab>Steps Text</Tab>
          <Tab>Links</Tab>
        </TabList>
        <TabPanel>
          <div className="my-2">
            <h1 className="text-3xl mb-2 mt-2 text-blue-400 p-2 m-2">
              {guideData.guideTitle}
            </h1>
          </div>
          {/* Responsive grid setup */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {guideData.steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden"
              >
                {/* Fixed or flexible height for the image container and no flex-grow */}
                <div className="bg-gray-100 flex-grow-0">
                  <img
                    src={
                      guideData.firebaseJSON
                        ? guideData.firebaseJSON[index]
                        : step.screenshotUrl
                    }
                    alt={`Step ${index + 1}`}
                    className="w-full h-full object-cover" // Example height of 48 (adjust as needed)
                  />
                </div>
                {/* Flex container for the text, growing to take up remaining space */}
                <div className="flex-grow p-4 flex flex-col justify-end">
                  Step {index}:
                  <h2 className="text-lg font-semibold">{step.stepTitle}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: step.stepDescription }}
                    className="prose" // Use Tailwind Typography plugin (prose) for better text formatting
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-center space-y-4 p-6 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full  mb-12 mt-12">
              <p className="text-lg font-semibold text-gray-700">
                HTML Copy Section
              </p>
              <textarea
                readOnly
                value={htmlString}
                className="mt-12 mb-12 w-full h-64 p-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
              ></textarea>
            </div>
            <button
              onClick={() => copyToClipboard(htmlString)}
              className="px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded transition-colors duration-150 ease-in-out focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Copy HTML
            </button>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="space-y-4">
            {guideData.steps.map((step, index) => (
              <div key={index}>
                <h2 className="text-lg font-semibold">{step.stepTitle}</h2>
                <p>{step.stepDescription}</p>
                {/* Using a variable to decide which image URL to use */}
                <pre className="bg-gray-200 p-2 overflow-auto">
                  {`![Step ${index + 1}](${
                    guideData.firebaseJSON
                      ? guideData.firebaseJSON[index]
                      : step.screenshotUrl
                  })\n### ${step.stepTitle}\n${step.stepDescription}`}
                </pre>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 p-6 bg-gray-50 rounded-lg shadow-md">
            <div className="w-full  mb-12 mt-12">
              <p className="text-lg font-semibold text-gray-700">
                Markdown Copy Section
              </p>
              <textarea
                readOnly
                value={markdownString}
                className="w-full h-64 p-4 text-sm bg-white border border-gray-300 rounded-lg"
              ></textarea>
              <button
                onClick={() => copyToClipboard(markdownString)}
                className="px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
              >
                Copy Markdown
              </button>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <h1 className="text-3xl mb-2 mt-2 text-blue-400 p-2 m-2">
            {guideData.guideTitle}
          </h1>
          <div className="space-y-4">
            {guideData.steps.map((step, index) => (
              <div key={index}>
                <h2 className="text-lg font-semibold">{step.stepTitle}</h2>
                <p>{step.stepDescription}</p>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <p className="text-2xl font-bold text-blue-400 mt-4 mb-6">
            {guideData.guideTitle}
          </p>
          <div className="space-y-6">
            {guideData.steps.map((step, index) => (
              <div
                key={index}
                className="transition duration-300 ease-in-out hover:bg-gray-50 p-6 shadow-lg rounded-xl bg-white"
              >
                <p className="text-sm font-medium text-indigo-600">
                  Step {index + 1}:
                </p>
                <h2 className="text-xl font-semibold text-gray-800 mt-2">
                  {step.stepTitle}
                </h2>
                <img
                  src={
                    guideData.firebaseJSON
                      ? guideData.firebaseJSON[index]
                      : step.screenshotUrl
                  }
                  alt={`Screenshot of ${step.stepTitle}`}
                  className="max-w-xs w-full" // Tailwind CSS class to make image responsive and limit its maximum width to 400px
                  style={{ maxWidth: "600px" }} // Inline style as an alternative to ensure max width
                />
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel>
          <p className="text-2xl font-bold text-blue-400 mt-4 mb-6">
            {guideData.guideTitle}
          </p>
          <div className="space-y-6">
            {guideData.steps.map((step, index) => (
              <div
                key={index}
                className="transition duration-300 ease-in-out hover:bg-gray-50 p-6 shadow-lg rounded-xl bg-white"
              >
                <p className="text-sm font-medium text-indigo-600">
                  Step {index + 1}:
                </p>
                <h2 className="text-xl font-semibold text-gray-800 mt-2">
                  {step.stepTitle}
                </h2>
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel>
          {guideData.publishingTime && guideData.settings.public && (
            <>
              {" "}
              <div className="text-xl font-semibold mb-4 p-2 m-2">
                Sharing Settings
              </div>
              <div className="mt-4 p-4 mt-8 mb-8 bg-blue-100 rounded-lg border border-blue-200 m-2 p-2">
                <div className="text-sm text-blue-900 font-semibold m-2">
                  Anyone with link can view{" "}
                  <a
                    href={publicLink}
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 ml-1 mr-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View link
                  </a>
                  <button
                    onClick={() => copyToClipboard(publicLink)}
                    className=" items-center justify-center text-gray-500 hover:text-gray-700"
                    title="Copy link"
                  >
                    <FiCopy className="text-lg" />
                  </button>
                </div>
              </div>
            </>
          )}

          {guideData.publishingTime && guideData.settings.public && (
            <div className="my-4 m-3 p-3">
              {/* Display options for copying embed codes and direct URLs */}
              <div className="text-xl font-bold mb-2">
                Embed and Direct URLs:
              </div>

              {/* Landscape Section */}
              <div className="mb-4">
                <div className="text-sm mb-2">Landscape Orientation:</div>
                {/* Landscape Embed Code */}
                <div className="p-2 bg-gray-100 rounded">
                  <code className="text-xs">{`<iframe src="${embedLink}" width="960" height="540" frameborder="0" allowfullscreen></iframe>`}</code>
                </div>
                <button
                  onClick={copyLandscapeEmbedCodeToClipboard}
                  className="mt-2 inline-flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors duration-150"
                >
                  Copy Landscape Embed Code
                </button>
                {/* Landscape Direct URL */}
                <button
                  onClick={copyLandscapeUrlToClipboard}
                  className="ml-4 mt-2 inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-150"
                >
                  Copy Landscape Share URL
                </button>
                <button
                  onClick={toggleLandscapePreview}
                  className="ml-4 mt-2 inline-flex items-center px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 transition-colors duration-150"
                >
                  {showLandscapePreview ? "Hide" : "Preview"} Landscape iFrame
                </button>
                {showLandscapePreview && (
                  <div className="mt-4">
                    <iframe
                      src={embedLink}
                      width="960"
                      height="540"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>

              {/* Portrait Section */}
              <div>
                <div className="text-sm mb-2">Portrait Orientation:</div>
                {/* Portrait Embed Code */}
                <div className="p-2 bg-gray-100 rounded">
                  <code className="text-xs">{`<iframe src="${embedLink}" width="540" height="960" frameborder="0" allowfullscreen></iframe>`}</code>
                </div>
                <button
                  onClick={copyPortraitEmbedCodeToClipboard}
                  className="mt-2 inline-flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition-colors duration-150"
                >
                  Copy Portrait Embed Code
                </button>
                {/* Portrait Direct URL */}
                <button
                  onClick={copyPortraitUrlToClipboard}
                  className="ml-4 mt-2 inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-150"
                >
                  Copy Portrait Share URL
                </button>
                <button
                  onClick={togglePortraitPreview}
                  className="ml-4 mt-2 inline-flex items-center px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 transition-colors duration-150"
                >
                  {showPortraitPreview ? "Hide" : "Preview"} Portrait iFrame
                </button>
                {showPortraitPreview && (
                  <div className="mt-4">
                    <iframe
                      src={embedLink}
                      width="540"
                      height="960"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default GuideShare;
