// git wVdZ4zMt1PYD6mmNflk6ZApiZEe2

export const Featured = [
  {
    isPublic: true,
    createdTime: 1710269461000,
    guideTitle:
      "How to Guide - How to use Kimavi Video Creator to Create Your First Video Tutorial ",
    recordingSessionId: "recording_1730710552564_b673f7908a9f9",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["Kimavi", "HowTo"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    isPublic: true,
    createdTime: 1710269460000,
    guideTitle:
      "How to Guide - How to Cancel Your Adobe Account Subscription AND Get Free 60 day Subscription",
    recordingSessionId: "recording_1730421466627_5c77ee3fb60bb",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["Adobe"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle:
      "How to find 12 Volt battery, in Tesla model 3, or model Y, and jump start another car",
    recordingSessionId: "recording_1719941477648_396209",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["Tesla", "Battery", "JumpStart", "12v"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle:
      "PowerPoint Mobile Tips: How to restore a previous version of a file on your iPhone or Android",
    recordingSessionId: "recording_1715438335066_409879",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["PowerPoint", "Tip", "Mobile", "Restore"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle:
      "PowerPoint Tip: Rehearse with Coach, on Your iPhone & Android - Master Your Presentation Skills",
    recordingSessionId: "recording_1715059034993_398327",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["PowerPoint", "Tip", "Rehearse", "Coach"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle:
      "PowerPoint Tip, How to restore, A previously saved version of PowerPoint file, Web or Desktop File",
    recordingSessionId: "recording_1715028107857_69e3286ae4754",
    guideLastSavedTimeStamp: 171026949999,
    tags: ["PowerPoint", "Tip", "Restore"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle:
      "How to Guide - Meta AI - How to have fun with Meta Image Generators",
    recordingSessionId: "recording_1713841889878_b5b4ac9cfa1d",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["meta", "ai", "images"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },

  {
    isPublic: true,
    createdTime: 1710269452140,
    guideTitle: "Google Docs Formatting for Android: A Step-by-Step Guide",
    recordingSessionId: "recording_1712850437948_182667",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["Formatting", "Google", "Docs", "Android"],
    uid: "bR6ufDGyfeZIownnKN8BCW706se2",
  },
  {
    isPublic: true,
    createdTime: 1710269452139,
    guideTitle:
      "How to Collaborate in Real-Time with Google Docs & Sheets on Android: Work Together on the Move",
    recordingSessionId: "recording_1712924615390_868552",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["Collaborate", "Google", "Docs"],
    uid: "bR6ufDGyfeZIownnKN8BCW706se2",
  },
  {
    isPublic: true,
    createdTime: 1710269452138,
    guideTitle: "How to Screen Cast Android to PC (Without Third-Party Apps)",
    recordingSessionId: "recording_1712379496792_550540",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["Android", "PC", "ScreenCast"],
    uid: "bR6ufDGyfeZIownnKN8BCW706se2",
  },
  {
    isPublic: true,
    createdTime: 1710269452137,
    guideTitle: "How to Safely Watch a Solar Eclipse: A Comprehensive Guide",
    recordingSessionId: "recording_1712419270095_141637",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["SolarEclipse", "Safety", "EyeProtection"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "Maximizing Rental Income Tax Deductions with TurboTax 2023: A Comprehensive Guide",
    recordingSessionId: "recording_1711867514055_2e6af5342e498",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
    tags: ["TaxDeductions", "TurboTax2023", "PropertyTax", "TaxSavings"],
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "How to Use Google Maps for Walking Directions to a Nearby Park",
    recordingSessionId: "recording_1711386443960_556407",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
    tags: ["GoogleMaps", "WalkingDirections", "ParkFinder", "MobileApp"],
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "How to Use ChatGPT Mobile App for Household Fixes - From Dishwasher to Washer via Mobile Camera",
    recordingSessionId: "recording_1711328529796_434254",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
    tags: ["ChatGPT", "MobileApp", "HouseholdFixes"],
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "How to Master Cancelling Amazon Subscribe and Save Subscriptions for Savings",
    recordingSessionId: "recording_1711151617213_a1235c6ee91fb",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",

    tags: ["Amazon", "SaveMoney", "SubscriptionManagemen"],
  },

  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "How to file QSBS, Section 1202, of IRS, The ability to lock in a 0% capital gains tax, TurboTax",
    recordingSessionId: "recording_1710885102355_016924f365c35",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
    tags: ["turbotaxonline", "intuit", "QSBS", "taxes", "capitalgains", "1202"],
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "Back Tap in iPhone, How to start iPhone backlight, by double tapping, on the back of iPhone",
    recordingSessionId: "recording_1710801416482_510852",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    tags: ["iphone", "backtap"],
  },
  {
    guideLastSavedTimeStamp: 1710393317891,
    guideTitle:
      "How to Guide - Wells Fargo Bank | How to send money via Zelle to any mobile number or email address",
    recordingSessionId: "recording_1710617314996_9e00f868b670d",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
    tags: ["wellsfargo", "zelle"],
  },
  {
    guideLastSavedTimeStamp: 1710393317878,
    guideTitle: "How to ensure that VS code can auto format code on save!",
    recordingSessionId: "recording_1710658769883_927321",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    tags: ["vscode", "settings"],
  },
  {
    guideLastSavedTimeStamp: 1710393317878,
    guideTitle:
      "How to easily cancel Apple Recurring Subscriptions and Save money!!",
    recordingSessionId: "recording_1710383195602_943419",
    createdTime: 1710383195602,
    isPublic: true,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    tags: ["apple", "subscriptions"],
  },

  {
    isPublic: true,
    createdTime: 1710269452137,
    guideTitle:
      "How to Guide - How to ensure that ChatGPT is not training its models based on your confidential data",
    recordingSessionId: "recording_1710269452137_7b3f2a88975b4",
    guideLastSavedTimeStamp: 1710305396627,
    tags: ["chat", "openai", "privacy", "settings"],
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
  },
  {
    createdTime: 1709794157015,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    tags: ["britishairways", "travel", "points"],
    guideLastSavedTimeStamp: 1710140414243,
    isPublic: true,
    recordingSessionId: "recording_1710478147507_5b2b5d744597e",
    guideTitle:
      "How to Guide - British Airways | How to Add Frequent Flyer Number To an Existing Booking",
  },

  {
    createdTime: 1709794157015,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    tags: ["chat", "openai"],
    guideLastSavedTimeStamp: 1710140414243,
    isPublic: true,
    recordingSessionId: "recording_1709794157014_be32a873779a7",
    guideTitle:
      "How to Guide, Set up custom instructions at ChatGPT, Accelerate your Team Workspace Workflow",
  },
  {
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    guideLastSavedTimeStamp: 1710140299726,
    tags: ["delta", "points"],
    createdTime: 1709914341381,
    recordingSessionId: "recording_1709914341381_f8c17946b2c02",
    guideTitle:
      "How to Guide - How to Add Frequent Flyer Number on Delta Air Lines - Existing Reservation",
    isPublic: true,
  },
  {
    createdTime: 1709971042833,
    tags: ["kimavi"],
    guideTitle:
      "How to Guide - How to use Kimavi Chrome Browser Extension - For Creating How To Videos and GIF with Generative AI",
    guideLastSavedTimeStamp: 1710132164956,
    uid: "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
    recordingSessionId: "recording_1709971042833_7218fbbdad965",
    isPublic: true,
  },
];
